<div class="media profile-media user-tour">
  <img class="b-r-10" width="40" src="assets/images/user/farmer_m.png" alt="" />
  <div class="media-body">
    <span>{{ userData.name }}</span>
    <p class="mb-0 font-roboto">{{ userData.role_name }} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div text-center">
  <li>
    <span><i class="fa fa-clock-o"></i> Sesi: {{ remainingTime | timeAgo }}</span>
  </li>
  <li>
    <a (click)="refresh()"><span class="text-white btn btn-sm btn-success gf-wrap-text"><i class="fa fa-refresh"></i>
        Refresh</span></a>
  </li>
  <li>
    <a (click)="logout()"><span class="text-white btn btn-sm btn-danger"><i class="fa fa-sign-out"></i>
        Logout</span></a>
  </li>
</ul>