import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CrudService } from "@services/crud.service";

interface AssetDetail {
  description: string;
  kode_unker: string;
  name: string;
  email: string;
  phone: string;
  qty: number;
}

interface AssetFacility {
  name: string;
  qty: number;
}

interface Asset {
  description: string;
  title: string;
  domain: string;
  email: string;
  name: string;
  unker: string;
  date: string;
  start_time: string;
  end_time: string;
}

interface ReportData {
  asset: Asset;
  asset_facility: AssetFacility[];
  asset_detail: AssetDetail[];
}

@Component({
  selector: "app-asset-attendance-report",
  templateUrl: "./asset-attendance-report.component.html",
  styleUrls: ["./asset-attendance-report.component.scss"]
})
export class AssetAttendanceReportComponent {
  private readonly FIELD = "asset-list";
  protected reportID: string;
  protected data: ReportData | null = null;
  protected issig: number;

  constructor(
    private route: ActivatedRoute,
    private service: CrudService,
  ) {
    this.reportID = this.route.snapshot.params.id;
    this.route.queryParams.subscribe(params => {
      this.issig = params['signature'];
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  printReport() {
    window.print();
  }

  private loadData(): void {
    this.service.getAttendanceReport(this.reportID).subscribe({
      next: (res) => {
        console.log(res);
        this.data = res.data;
      },
      error: (err) => { console.log(err); },
    });
  }

  formatDateTime(date: string, startTime: string, endTime: string): string {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('id-ID', { day: 'numeric', month: 'long', year: 'numeric' });

    const startTimeObj = new Date(`1970-01-01T${startTime}`);
    const formattedStartTime = startTimeObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    const endTimeObj = new Date(`1970-01-01T${endTime}`);
    const formattedEndTime = endTimeObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${formattedDate}, ${formattedStartTime} - ${formattedEndTime}`;
  }
}
