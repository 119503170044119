import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { EmailVerifyComponent } from './pages/auth/email-verify/email-verify.component';
import { ContentComponent } from './layout/content/content.component';
import { FullscreenImageComponent } from './pages/fullscreen-image/fullscreen-image.component';
import { AisApiComponent } from './pages/ais-api/ais-api.component';
import { RedirectUrlComponent } from './pages/redirect-url/redirect-url.component';
import { SsoAccessComponent } from './pages/sso-access/sso-access.component';
import { contentRoutes } from './_routes/routes';
import { RefsComponent } from './pages/refs/refs.component';
import { OrgChartComponent } from './pages/org-chart/org-chart.component';
import { FormComponent } from './pages/form/form.component';
import { MainComponent as AssetManagementAccess } from './pages/assets-management/main/main.component';
import { AssetListComponent } from './pages/assets-management/asset-list/asset-list.component';
import { AssetReportComponent } from './pages/asset-report/asset-report.component';
import { AssetAttendanceComponent } from './pages/asset-attendance/asset-attendance.component';
import { AssetAttendanceReportComponent } from './pages/asset-attendance-report/asset-attendance-report.component';
import { AssetPublicationComponent } from './pages/assets-management/asset-publication/asset-publication.component';
import { AssetReservationComponent } from './pages/assets-management/asset-reservation/asset-reservation.component';
import { TestMapComponent } from './pages/test-map/test-map.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'test',
    component: TestMapComponent
  },
  {
    path: 'refs',
    component: RefsComponent
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/register',
    component: RegisterComponent
  },
  {
    path: 'auth/forgot-password',
    component: ForgotComponent
  },
  {
    path: 'auth/email-verification/:verificationCode',
    component: EmailVerifyComponent,
  },
  {
    path: 'fullscreen-image/:imageUrl',
    component: FullscreenImageComponent
  },
  {
    path: 'v1/:endpoint',
    component: AisApiComponent
  },
  {
    path: 'chart/:id',
    component: OrgChartComponent
  },
  {
    path: 'form/:id',
    component: FormComponent
  },
  {
    path: 'asset-report/:id',
    component: AssetReportComponent
  },
  {
    path: 'asset-report/:id',
    component: AssetReportComponent
  },
  {
    path: 'asset-attendance-report/:id',
    component: AssetAttendanceReportComponent
  },
  {
    path: 'asset-attendance/:id',
    component: AssetAttendanceComponent
  },
  {
    path: 'sso-integration',
    component: SsoAccessComponent
  },
  {
    path: 'asset-management-access',
    component: AssetManagementAccess
  },
  {
    path: 'asset-management-list-access',
    component: AssetListComponent
  },
  {
    path: 'asset-management-schedule-access',
    component: AssetPublicationComponent
  },
  {
    path: 'asset-management-reservation-access',
    component: AssetReservationComponent
  },
  {
    path: '',
    component: ContentComponent,
    children: contentRoutes,
  },
  {
    path: 's/:endpoint',
    component: RedirectUrlComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
