import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { env } from "../../_env/env";

@Injectable({
  providedIn: "root",
})
export class PolygonService {
  private loadDataSubject = new Subject<void>();
  public visibleChanged = new EventEmitter<boolean>();
  public loadData$ = this.loadDataSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  getData(id: string, uid: string): Observable<any> {
    let params = new HttpParams();
    params = params.set("id", id);
    params = params.set("uid", uid);

    return this.http.get(env.v1_API + "polygon-by-category-id", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: params
    });
  }
}
