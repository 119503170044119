import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { CrudService } from '../../_services/crud.service';

@Component({
  selector: 'app-test-map',
  templateUrl: './test-map.component.html',
  styleUrls: ['./test-map.component.scss']
})
export class TestMapComponent implements OnInit {
  options: L.MapOptions;
  layers: L.Layer[];
  geoJsonLayer: L.GeoJSON;
  adm1Data: any;

  constructor(private crud: CrudService) { }

  ngOnInit(): void {
    this.initializeMapOptions();

    // Fetch the data using the service
    this.crud.getAdm1GEOJSON().subscribe({
      next: (res) => {
        console.log(res);
        this.adm1Data = res;
        // Initialize the GeoJSON layer after data is fetched
        this.initializeGeoJSONLayer();
      },
      error: (err) => {
        console.error('Error fetching data', err);
      }
    });
  }

  initializeMapOptions() {
    this.options = {
      center: L.latLng(-2.5489, 118.0149), // Center coordinates of Indonesia
      zoom: 5,
    };
  }

  parseAdm1Data(rawData): any {
    const features = rawData.map((item) => {
      // Parse the stringified 'coordinates' field
      const coordinates = JSON.parse(item.coordinates);

      // Parse 'geometry' if it's a string
      coordinates.features.forEach((feature) => {
        if (typeof feature.geometry === 'string') {
          feature.geometry = JSON.parse(feature.geometry);
        }

        // Assign properties
        feature.properties = {
          name: item.name,
          code: item.code,
          adm0: item.adm0,
          adm0_code: item.adm0_code,
          shape_leng: item.shape_leng,
          shape_area: item.shape_area,
        };
      });

      return coordinates.features[0];
    });

    return {
      type: 'FeatureCollection',
      features: features,
    };
  }

  getRandomColor(): string {
    const colors = ['red', 'green', 'yellow'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  initializeGeoJSONLayer() {
    // Parse the adm1 data using the fetched data
    const geojsonData = this.parseAdm1Data(this.adm1Data);

    this.geoJsonLayer = L.geoJSON(geojsonData as any, {
      style: (feature) => this.styleFeature(feature),
      onEachFeature: (feature, layer) => this.onEachFeature(feature, layer),
    });

    this.layers = [this.geoJsonLayer];
  }

  styleFeature(feature): L.PathOptions {
    return {
      fillColor: this.getRandomColor(),
      weight: 1,
      opacity: 1,
      color: 'white',
      fillOpacity: 0.7,
    };
  }

  onEachFeature(feature, layer) {
    layer.on({
      click: (e) => this.onFeatureClick(e, feature),
    });
  }

  onFeatureClick(e, feature) {
    const areaName = feature.properties.name; // Use 'name' from your data
    alert(`You clicked on ${areaName}`);
  }
}
