<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="register-main">
            <div class="form-header align-items-center">
              <a class="logo" routerLink="/">
                <img class="img-fluid for-light" width="100px" src="assets/logo.png" alt="Kementerian Pertanian" />
              </a>
              <div class="header-text">
                <h4>Daftar</h4>
                <p style="margin: 0;">Isi data berikut untuk melakukan registrasi</p>
              </div>
            </div>
            <form *ngIf="!isLoggedIn" class="theme-form" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
              novalidate>
              <ul class="nav nav-tabs custom-tabs justify-content-center mt-2 mb-2">
                <li class="nav-item">
                  <a class="nav-link custom-tab-link" [class.active]="activeTab === 'umum'" (click)="switchTab('umum')"
                    href="javascript:void(0)">Umum</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link custom-tab-link" [class.active]="activeTab === 'pegawai'"
                    (click)="switchTab('pegawai')" href="javascript:void(0)">Pegawai</a>
                </li>
              </ul>
              <hr/>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="col-form-label">Nama Lengkap</label>
                    <input type="text" class="form-control" required="" name="name" placeholder="Nama Lengkap"
                      autocomplete="off" [(ngModel)]="form.name" #name="ngModel" />
                    <div *ngIf="name.errors && f.submitted" class="text text-danger mt-1">
                      <div *ngIf="name.errors['required']">Nama Lengkap tidak boleh kosong</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Username</label>
                    <input type="text" class="form-control" required="" name="username" placeholder="Username"
                      autocomplete="off" [(ngModel)]="form.username" #username="ngModel"
                      (input)="formatUsername($event)"/>
                    <div *ngIf="username.errors && f.submitted" class="text text-danger mt-1">
                      <div *ngIf="username.errors['required']">Username tidak boleh kosong</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input type="email" class="form-control" required="" name="email" placeholder="email@email.com"
                      autocomplete="off" [(ngModel)]="form.email" #email="ngModel"
                      (input)="formatEmail($event)"/>
                    <div *ngIf="email.errors && f.submitted" class="text text-danger mt-1">
                      <div *ngIf="email.errors['required']">Email tidak boleh kosong</div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="activeTab === 'pegawai'">
                    <label class="mt-4">
                      <input class="mt-2" type="checkbox" (click)="openModal()" [(ngModel)]="acceptedTerms" /> Saya menyetujui syarat dan
                      ketentuan
                    </label>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group" *ngIf="activeTab === 'pegawai'">
                    <label class="col-form-label">Unit Kerja</label>
                    <ng-select [items]="unker" bindLabel="nama_unker" bindValue="kode_unker" placeholder="Select Unit Kerja"
                      [(ngModel)]="form.unker" [searchable]="true" [clearable]="true" (change)="onUnkerChange($event)">
                    </ng-select>
                  </div>
                  <div class="form-group" *ngIf="activeTab === 'pegawai'">
                    <label class="col-form-label">NIP</label>
                    <input type="text" class="form-control" name="nip" placeholder="NIP"
                      autocomplete="off" [(ngModel)]="form.nip" #nip="ngModel" />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input type="password" class="form-control" required="" name="password" autocomplete="off"
                      [(ngModel)]="form.password" #password="ngModel" minlength="8" />
                    <div *ngIf="password.errors && f.submitted" class="text text-danger mt-1">
                      <div *ngIf="password.errors['required']">Password tidak boleh kosong</div>
                      <div *ngIf="password.errors['minlength']">Password must be at least 8 characters</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Konfirmasi Password</label>
                    <input type="password" class="form-control" required="" name="confirm_password" autocomplete="off"
                      [(ngModel)]="form.confirm_password" #confirm_password="ngModel" minlength="8"
                      [equalTo]="form.password" />
                    <div *ngIf="confirm_password.errors && f.submitted" class="text text-danger mt-1">
                      <div *ngIf="confirm_password.errors['required']">Konfirmasi Password tidak boleh kosong</div>
                      <div *ngIf="confirm_password.errors['minlength']">
                        Konfirmasi Password must be at least 8 characters
                      </div>
                    </div>
                    <div *ngIf="!isEqual" class="text text-danger mt-1">
                      <div>Konfirmasi Password harus sama dengan Password</div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="activeTab === 'umum'">
                    <label class="mt-4">
                      <input class="mt-2" type="checkbox" (click)="openModal()" [(ngModel)]="acceptedTerms" /> Saya menyetujui syarat dan
                      ketentuan
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.submitted && isRegisterFailed" class="text text-danger mt-1">
                  Gagal daftar: {{ errorMessage }}
                </div>
              </div>
              <div class="form-group mb-0">
                <button class="btn gf_btn d-block w-100" [disabled]="!acceptedTerms" type="submit">Buat Akun</button>
              </div>
              <p class="mt-4 mb-0">Sudah punya akun?<a class="ms-2" [routerLink]="'/auth/login'">Login</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
