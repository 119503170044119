<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">

      <div class="card shadow">
        <div class="card-body custom-datatable noscroll">
          <h5 class="ribbon ribbon-bookmark ribbon-primary fw-bold">
            <img class="mb-1" width="20" src="assets/images/logo/logo.png">
            Ruang Rapat
          </h5>
          <app-meeting-room></app-meeting-room>
          <!-- <app-calendar></app-calendar> -->
        </div>
      </div>

      <div class="card shadow">
        <div class="card-body custom-datatable noscroll">
          <div class="custom-datatable">
            <h5 class="ribbon ribbon-bookmark ribbon-primary fw-bold">
              <img class="mb-1" width="20" src="assets/images/logo/logo.png">
              Kelola Asset / Ruang Rapat
            </h5>
            <div class="card-body mt-4 mb-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div class="row mb-3 d-flex justify-content-center">
                    <div class="col-lg-3 col-4 user-image bg-card-2 mb-2">
                      <a [routerLink]="['/asset-management-list' + routerLinkSuffix]"
                        routerLinkActive="router-link-active">
                        <div class="image-container">
                          <img width="100" class="top-right-img" src="assets/assets.png" alt="ghaly fadhillah">
                          <div class="text-centered text-white">
                            <h4 class="st-1 lh"> Daftar Asset </h4>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-lg-3 col-4 user-image bg-card-2 mb-2">
                      <a [routerLink]="['/asset-management-schedule' + routerLinkSuffix]"
                        routerLinkActive="router-link-active">
                        <div class="image-container">
                          <img height="100" class="top-right-img" src="assets/booking.png" alt="ghaly fadhillah">
                          <div class="text-centered text-white">
                            <h4 class="st-1 lh"> Publikasi Asset </h4>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-lg-3 col-4 user-image bg-card-2 mb-2">
                      <a [routerLink]="['/asset-management-reservation' + routerLinkSuffix]"
                        routerLinkActive="router-link-active">
                        <div class="image-container">
                          <img height="100" class="top-right-img" src="assets/reservation.png" alt="ghaly fadhillah">
                          <div class="text-centered text-white">
                            <h4 class="st-1 lh"> Data Reservasi </h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>