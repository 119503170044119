import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './_interceptor/auth.interceptor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { EmailVerifyComponent } from './pages/auth/email-verify/email-verify.component';
import { FullscreenImageComponent } from './pages/fullscreen-image/fullscreen-image.component';
import { AisApiComponent } from './pages/ais-api/ais-api.component';
import { RedirectUrlComponent } from './pages/redirect-url/redirect-url.component';
import { UrlShortenerComponent } from './pages/url-shortener/url-shortener.component';
import { SsoAccessComponent } from './pages/sso-access/sso-access.component';
import { RefsComponent } from './pages/refs/refs.component';
import { FormComponent } from './pages/form/form.component';
import { OrgChartComponent } from './pages/org-chart/org-chart.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { AssetReportComponent } from './pages/asset-report/asset-report.component';
import { AssetAttendanceComponent } from './pages/asset-attendance/asset-attendance.component';
import { AssetAttendanceReportComponent } from './pages/asset-attendance-report/asset-attendance-report.component';
import { TestMapComponent } from './pages/test-map/test-map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgSelectModule } from '@ng-select/ng-select';
import { env } from './_env/env';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    EmailVerifyComponent,
    FullscreenImageComponent,
    AisApiComponent,
    RedirectUrlComponent,
    UrlShortenerComponent,
    SsoAccessComponent,
    RefsComponent,
    FormComponent,
    OrgChartComponent,
    AssetReportComponent,
    AssetAttendanceComponent,
    AssetAttendanceReportComponent,
    TestMapComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgSelectModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastModule,
    TooltipModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 3000,
      progressBar: true,
    }),
    AppRoutingModule,
    FormsModule,
    RecaptchaV3Module,
    HttpClientModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    OrganizationChartModule,
    PanelModule,
    LeafletModule,
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: env.TBtwnT_kX,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(env.qu2h67l8),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
