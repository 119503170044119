<swiper [direction]="'vertical'" [loop]="true" [autoHeight]="true" [allowTouchMove]="false"
  [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <ng-template data-swiper-autoplay="2500" swiperSlide>
    <div class="d-flex h-100">
      <h5 class="mb-0 f-w-500"><span class="font-primary">PORTAL PERTANIAN TERINTEGRASI</span></h5>
    </div>
  </ng-template>
  <ng-template data-swiper-autoplay="5000" swiperSlide>
    <div class="d-flex h-100">
      <h6 class="f-w-500"><span class="font-primary">KEMENTERIAN PERTANIAN REPUBLIK INDONESIA &copy;
          {{ thisYear }}</span></h6>
    </div>
  </ng-template>
</swiper>