<ng-template #myUrlListModal let-modal>
  <div class="modal-body">
    <div class="custom-datatable">
      <h5 class="ribbon ribbon-bookmark ribbon-info fw-bold">{{ modalTitle }}</h5>
      <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
      <br />
      <hr />
      <app-module-detail-cu [moduleID]="moduleID" [isSection]="isSection"
        *ngIf="visibleSection1"></app-module-detail-cu>
      <div class="d-flex justify-content-between mb-3 row">
        <div class="ms-auto">
          <a class="btn btn-sm btn-primary fw-bold gf-wrap-text" style="margin-left: 5px" (click)="createURL()">
            <i class="fa fa-edit"></i> Buat URL
          </a>
          <button *ngIf="isDelete" class="btn btn-danger fw-bold gf-wrap-text" style="margin-left: 5px"
            (click)="doDelete()">
            <i class="fa fa-trash"></i> Hapus
          </button>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table">
          <thead class="bg-info">
            <tr>
              <th [ngClass]="['gf-wrap-text', 'text-white']" scope="col">
                No
              </th>
              <th [ngClass]="['gf-wrap-text', 'text-white']" scope="col">
                URL
              </th>
              <th [ngClass]="['gf-wrap-text', 'text-white']" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="data.length <= 0">
              <td class="text-center" colspan="6">Data tidak ditemukan</td>
            </tr>
            <tr *ngFor="let item of data; let i = index" [ngClass]="{'blink': i === 0 && newItem}">
              <td>
                {{ i + 1 }}
              </td>
              <td>
                <div class="text-dark">
                  <strong>Shorten URL:</strong> {{ baseUrl }}/s/{{ item.endpoint }}<br />
                  <strong>Org URL:</strong> {{ item.url }} <br />
                  <strong>Desc:</strong> {{ item.description }} <br />
                  <strong>Expired:</strong> {{ item.expiredFormatted }} <br />
                </div>
              </td>
              <td>
                <button (click)="doCopy(item.endpoint);" class="btn btn-sm btn-primary mb-2">
                  <strong style="margin-right: 5px; white-space: nowrap;">Copy <i class="fa fa-copy"></i></strong>
                  <br />
                </button>
                <div class="action" *ngIf="!visible">
                  <li class="delete" style="white-space: nowrap;">
                    <strong style="margin-right: 5px;">Hapus </strong>
                    <input class="mt-2" type="checkbox" [checked]="isSelected(item.endpoint)"
                      (change)="onCheckboxChange(item.id, item.endpoint)" />
                  </li>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>