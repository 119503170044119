import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { env } from "@env/env";

@Injectable({
  providedIn: "root"
})
export class AisApiService {

  constructor(
    private http: HttpClient
  ) { }

  public getApi(endpoint: string, key: string): Observable<any> {
    const url = `${env.v1_API}${endpoint}`;
    let params = new HttpParams();
    params = params.set("key", key);

    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: params,
    });
  }
}
