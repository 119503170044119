import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root', // Ensures a single instance across the app
})
export class ActionService {
  // desa level
  private whatLevelSubject = new BehaviorSubject<boolean>(false);
  whatLevel$ = this.whatLevelSubject.asObservable();

  // untuk trigger value
  private levelSubject = new BehaviorSubject<string>(''); // Default empty or placeholder
  level$ = this.levelSubject.asObservable();

  // untuk trigger value
  private locationSubject = new BehaviorSubject<string>(''); // Default empty or placeholder
  location$ = this.locationSubject.asObservable();

  // untuk trigger modal
  private openModalSubject = new Subject<void>();
  private closeModalSubject = new Subject<void>();

  // for maps
  private areaClickedSource = new Subject<any>();
  areaClicked$ = this.areaClickedSource.asObservable();

  // Observable for components to subscribe to
  openModal$ = this.openModalSubject.asObservable();
  closeModal$ = this.closeModalSubject.asObservable();

  //desa
  setWhatLevel(value: boolean) {
    this.whatLevelSubject.next(value);
  }

  //desa
  setLevel(value: string) {
    this.levelSubject.next(value);
  }

  // Method to trigger the modal open event
  triggerOpenModal() {
    this.openModalSubject.next();
  }

  triggerCloseModal() {
    this.closeModalSubject.next();
  }

  setLocation(location: string) {
    this.locationSubject.next(location);
  }

  emitAreaClicked(areaData: any) {
    this.areaClickedSource.next(areaData);
  }

  triggerAreaClicked(feature: any) {
    this.areaClickedSource.next(feature);
  }
}
