import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { StorageService } from '@services/storage.service';
import { UserService } from '@services/user.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RecaptchaService } from '@services/recaptcha.service';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { SwalService } from '@services/swal.service';

const MSG_OPTIONS = {
  timer: 2000,
  timerProgressBar: true,
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private socialUser!: SocialUser;
  private form: any = {
    name: null,
    username: null,
    email: null,
    password: null,
  };

  protected isLoggedIn = false;
  protected isLoginFailed = false;
  protected isProcessing = false;
  protected errorMessage = '';

  constructor(
    private authService: AuthService,
    private stgService: StorageService,
    private usrService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private recaptchaService: RecaptchaService,
    private socialAuthService: SocialAuthService,
    private sW: SwalService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.handleUserLogin();
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isLoggedIn = !!user;
      this.handleSocialUser();
    });
  }

  handleUserLogin(): void {
    this.usrService.isLoggedIn().subscribe((r) => {
      if (r) {
        this.isLoggedIn = true;
        this.router.navigate(['/dashboard']);
      }
    });
  }

  handleSocialUser(): void {
    if (!this.socialUser) return;
    this.authService.IsRegistered(this.socialUser.email).subscribe({
      next: (res) => {
        this.handleSocialUserResponse(res);
      },
      error: (err) => { console.log(err); },
    });
  }

  handleSocialUserResponse(res: any): void {
    const rand = this.generateRandom4DigitNumber();
    this.form.name = `${this.socialUser.firstName} ${this.socialUser.lastName}`;
    this.form.username = `${this.socialUser.firstName.toLocaleLowerCase()}${rand}`;
    this.form.email = this.socialUser.email;
    this.form.password = this.socialUser.id;
    if (!res.data) {
      this.handleSocialUserRegistration();
    } else {
      this.handleSocialUserLogin();
    }
  }

  handleSocialUserRegistration(): void {
    this.authService.register(this.form.name, this.form.username, this.form.email, this.form.password, true, "", "").subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.handleSocialUserLogin();
        }
      },
      error: (err) => { console.log(err); },
    });
  }

  handleSocialUserLogin(): void {
    this.authService.login(this.form.email, this.form.password, true).subscribe({
      next: (res) => {
        this.handleSocialUserLoginResponse(res);
      },
      error: (err) => { console.log(err); },
    });
  }

  handleSocialUserLoginResponse(res: any): void {
    if (res.data.email === this.form.email) {
      this.stgService.saveUser(res);
      this.isLoginFailed = false;
      this.isLoggedIn = true;
      this.showSuccessMessage('Login berhasil');
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, MSG_OPTIONS.timer);
    }
  }

  generateRandom4DigitNumber(): number {
    return Math.floor(1000 + Math.random() * 9000);
  }

  setLoginBtnState(isProcessing: boolean): void {
    this.isProcessing = isProcessing;
    const loginBtn = document.getElementById('loginBtn');
    if (loginBtn) {
      loginBtn.innerHTML = isProcessing ? 'Processing ...' : 'Login';
    }
  }

  onSubmit(): void {
    if (this.isProcessing) { return; }
    const { username, password } = this.form;
    this.setLoginBtnState(true);
    this.isProcessing = true;

    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.recaptchaService.verifyRecaptcha(token).subscribe({
        next: (result) => {
          if (result.status === 200) {
            this.authService.login(username, password, false).subscribe({
              next: (res) => {
                this.handleUserLoginResponse(res);
              },
              error: (err) => {
                console.log(err);
                this.errorMessage = err.error.message.error;
                this.isLoginFailed = true;
                this.setLoginBtnState(false);
              },
            });
          } else {
            this.showErrorMessage('Validasi 3D reCAPTCHA gagal, silahkan refresh halaman');
          }
        },
        error: (err) => {
          this.handleLoginError('Validasi 3D reCAPTCHA gagal, silahkan refresh halaman ' + err);
        },
      });
    });
  }

  handleUserLoginResponse(res: any): void {
    this.setLoginBtnState(false);
    this.stgService.saveUser(res);
    this.isLoginFailed = false;
    this.isLoggedIn = true;
    this.showSuccessMessage('Login berhasil');
    setTimeout(() => {
      this.router.navigate(['/dashboard']);
    }, MSG_OPTIONS.timer);
  }

  showSuccessMessage(message: string): void {
    this.sW.sSwal(message);
  }

  showErrorMessage(message: string): void {
    this.handleLoginError(message);
  }

  handleLoginError(message: string): void {
    this.errorMessage = message;
    this.isLoginFailed = true;
    this.setLoginBtnState(false);
  }

  reloadPage(): void {
    window.location.reload();
  }
}
