import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { AuthService } from "../pages/auth/auth.service";
import { StorageService } from "@services/storage.service";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RoleGuard {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private authService: AuthService,
    private stgService: StorageService,
  ) { }

  public canActivate(): Observable<boolean> {
    const role = this.authService.validateRole();
    if (role !== null) {
      return role.pipe(
        map((res) => {
          if (res && res.data) {
            if (!this.stgService.isValidSSO()) {
              this.authService.logout().subscribe({
                next: (res) => {
                  if (res.status == 200) {
                    this.authService.handleLogoutSuccess();
                  }
                },
                error: (err) => { console.log(err.error.message.error); },
              });
            }
            this.isAuthenticatedSubject.next(true);
            return true;
          } else {
            this.router.navigate(["/dashboard"]);
            this.isAuthenticatedSubject.next(false);
            return false;
          }
        }),
        catchError(() => {
          this.router.navigate(["/auth/login"]);
          return of(false);
        })
      );
    }
    this.router.navigate(["/auth/login"]);
    return of(false);
  }
}
