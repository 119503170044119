<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body custom-datatable noscroll">
          <div class="custom-datatable">
            <h5 class="ribbon ribbon-bookmark ribbon-success fw-bold" (click)="goBack()">kembali</h5>
            <h5 class="ribbon ribbon-bookmark ribbon-right ribbon-primary fw-bold"><img class="mb-1" width="20" src="assets/images/logo/logo.png"> {{ module_title }}</h5>
            <br />
            <hr />
            <app-asset-list-cu *ngIf="visible"></app-asset-list-cu>
            <app-button-skeleton *ngIf="showSkeleton; else buttonContent"></app-button-skeleton>
            <ng-template #buttonContent>
              <div class="d-flex justify-content-between mb-3 row">
                <div class="col-lg-7">
                  <div class="input-group">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                    <input class="form-control bg-light-primary" id="search" type="search" [(ngModel)]="filter.search"
                      (input)="onSearchInput($event)" />
                  </div>
                </div>
                <div class="col-lg-5 d-flex justify-content-end">
                  <button *ngIf="!visible" class="btn btn-primary fw-bold gf-wrap-text" style="margin-left: 5px"
                    (click)="doCreate()">
                    <i class="fa fa-edit"></i> Data Baru
                  </button>
                  <button *ngIf="isDelete" class="btn btn-danger fw-bold gf-wrap-text" style="margin-left: 5px"
                    (click)="doDelete()">
                    <i class="fa fa-trash"></i> Hapus
                  </button>
                </div>
              </div>
            </ng-template>

            <app-table-skeleton *ngIf="showSkeleton; else tableContent"></app-table-skeleton>
            <ng-template #tableContent>
              <div class="table-responsive">
                <table class="table">
                  <thead class="bg-primary">
                    <tr>
                      <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(0, 'id')">
                        No <i [innerHTML]="sortE" [class.text-warning]="isSorted(0)"></i>
                      </th>
                      <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(1, 'name')">
                        Name <i [innerHTML]="sortE" [class.text-warning]="isSorted(1)"></i>
                      </th>
                      <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(2, 'description')">
                        Description <i [innerHTML]="sortE" [class.text-warning]="isSorted(2)"></i>
                      </th>
                      <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(3, 'kode_unker')">
                        Unit Kerja <i [innerHTML]="sortE" [class.text-warning]="isSorted(3)"></i>
                      </th>
                      <th [ngClass]="['gf-wrap-text']" scope="col">
                        Detail Asset
                      </th>
                      <th scope="col" *ngIf="!visible">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="loadingService.isLoading$ | async">
                      <td colspan="6" class="text-center">
                        <app-loading-icon></app-loading-icon>
                      </td>
                    </tr>
                    <tr *ngIf="data.length <= 0">
                      <td class="text-center" colspan="6">Data tidak ditemukan</td>
                    </tr>
                    <ng-container *ngIf="!(loadingService.isLoading$ | async)">
                      <tr *ngFor="let item of data; let i = index">
                        <td>
                          {{ (currentPage - 1) * pageSize + i + 1 }}
                        </td>
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          {{ item.description }}
                        </td>
                        <td>
                          {{ item.nama_unker }}
                        </td>
                        <td>
                          <i (click)="openRoomModal(item.id, item.name)" class="badge badge-sm badge-success pointer">Lihat Detail</i>
                        </td>
                        <td *ngIf="!visible">
                          <div class="action">
                            <li class="edit badge badge-md badge-info d-flex align-items-center"
                              (click)="report(item.id)">
                              <i class="fa fa-copy text-white"></i> Laporan
                            </li>
                            <li class="edit badge badge-md badge-success d-flex align-items-center"
                              (click)="doEdit(item.id)">
                              <i class="icon-pencil-alt text-white"></i> Edit
                            </li>
                            <li class="delete badge badge-md badge-danger d-flex align-items-center">
                              <i class="fa fa-trash text-white"></i>
                              <input type="checkbox" [checked]="isSelected(item.id)"
                                (change)="onCheckboxChange(item.id)" />
                            </li>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-between mt-3 mb-3">
                <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="selectedPageSize"
                  (change)="changePageSize()">
                  <option value="10">10 data</option>
                </select>
                <nav aria-label="...">
                  <ul class="pagination pagination-primary">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                      <a class="page-link" href="javascript:void(0)" (click)="onPageChange(1)">First</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === 1">
                      <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage - 1)">Prev</a>
                    </li>

                    <li class="page-item" *ngFor="let page of getPagesArray(); let i = index"
                      [class.active]="currentPage === page">
                      <a class="page-link" href="javascript:void(0)" (click)="onPageChange(page)">{{ page }}</a>
                    </li>

                    <li class="page-item" [class.disabled]="currentPage === totalPages">
                      <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage + 1)">Next</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === totalPages">
                      <a class="page-link" href="javascript:void(0)" (click)="onPageChange(totalPages)">Last</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>