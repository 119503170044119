import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, interval, Subject, debounceTime, distinctUntilChanged, switchMap, Observable, of } from "rxjs";
import { LoadingService } from "@services/loading.service";
import { CrudService } from "@services/crud.service";
import { SwalService } from "@services/swal.service";
import { Location } from '@angular/common';
import Swal from "sweetalert2";

@Component({
  selector: 'app-asset-reservation',
  templateUrl: './asset-reservation.component.html',
  styleUrls: ['./asset-reservation.component.scss']
})
export class AssetReservationComponent implements OnInit, OnDestroy {
  protected module_title: string = "Data Reservasi";
  private searchTerms = new Subject<string>();
  private totalRecords: number = 0;
  private sortedColumnIndex: number | null = null;
  protected data: any[] = [];
  protected filter: any = {
    search: "", sortBy: "id", sortOrder: "desc", page: 1, pageSize: 10,
  };
  protected currentPage: number = 1;
  protected pageSize: number = 10;
  protected totalPages: number = 0;
  protected selectedPageSize: number = 10;
  protected sortE: string = this.service.e.sort;
  private intervalSubs: Subscription[] = [];

  constructor(
    private service: CrudService,
    protected loadingService: LoadingService,
    private sW: SwalService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.search(term)),
      )
      .subscribe();

    this.loadData();
  }

  goBack(): void {
    this.location.back();
  }

  protected search(term: string): Observable<any[]> {
    if (term.length <= 0 || term.length >= 3) {
      this.loadingService.showLoading();
      this.filter.search = term.toLocaleLowerCase();
      this.loadData();
    }

    return of([]);
  }

  protected onSearchInput(event: any): void {
    const term = event.target.value.trim();
    this.loadingService.showLoading();
    this.currentPage = 1;
    this.filter.page = 1;
    this.searchTerms.next(term);
  }

  protected sort(columnIndex: number, str: string): void {
    if (this.filter.sortBy === str) {
      this.filter.sortOrder = this.filter.sortOrder === "asc" ? "desc" : "asc";
    } else {
      this.filter.sortOrder = "desc";
      this.filter.sortBy = str;
    }
    this.sortedColumnIndex = columnIndex;
    this.loadData();
  }

  protected isSorted(columnIndex: number): boolean {
    return this.sortedColumnIndex === columnIndex;
  }

  protected changePageSize(): void {
    this.filter.pageSize = this.selectedPageSize;
    this.loadData();
  }

  protected onPageChange(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.filter.page = newPage;
      this.loadData();
    }
  }

  protected getPagesArray(): number[] {
    const totalVisiblePages = 5;
    const pagesArray: number[] = [];
    const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2));
    const endPage = Math.min(this.totalPages, startPage + totalVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pagesArray.push(i);
    }

    return pagesArray;
  }

  private loadData(): void {
    this.loadingService.showLoading();
    this.service.getReservationHistory("self", this.filter).subscribe({
      next: (res) => {
        console.log(res);
        this.data = res.data.rows;
        this.totalRecords = res.data.meta.total;
        this.calculateTotalPages();
        this.data.forEach((item: any) => {
          const attendanceSub = interval(1000).pipe(
            switchMap(() => this.service.getAttendanceTotal(item.id))
          ).subscribe({
            next: (attendanceRes) => {
              item.attendanceTotal = attendanceRes.data.total;
            },
            error: (err) => { console.log('Error fetching attendance total', err); }
          });
          this.intervalSubs.push(attendanceSub);
        });
        this.loadingService.hideLoading();
      },
      error: (err) => {
        console.log(err);
        this.loadingService.hideLoading();
      },
    });
  }

  protected calculateTotalPages(): void {
    // Ensure page size is properly applied and totalPages is recalculated
    this.totalPages = Math.ceil(this.totalRecords / this.filter.pageSize);

    // If there are no records, ensure totalPages is at least 1
    if (this.totalPages < 1) {
      this.totalPages = 1;
    }
  }

  doSignature(id: string, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    let is_checked = 1;
    if (!isChecked) {
      is_checked = 0;
    }

    const formData: any = {
      asset_reservation_id: id,
      signature: is_checked,
    }
    this.service.doSignature(formData).subscribe({
      next: (res) => {
        this.loadData();
        Swal.fire({
          text: "Berhasil diubah",
          icon: "success",
        });
      },
      error: (err) => { console.log(err); },
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all intervals to prevent memory leaks
    this.intervalSubs.forEach(sub => sub.unsubscribe());
  }

  reloadPage(): void {
    window.location.reload();
  }

  report(id: string, signature: string): void {
    const baseUrl = window.location.origin;  // Get the base URL (main domain)
    const fullUrl = `${baseUrl}/asset-attendance-report/${id}?signature=${signature}`;
    window.open(fullUrl, '_blank');
  }

  attendance(id: string, signature: string): void {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/asset-attendance/${id}?signature=${signature}`;
    this.copyToClipboard(fullUrl);
    Swal.fire({
      text: "URL Berhasil dicopy",
      icon: "success",
    });
  }

  private copyToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  doDone(id: string): void {
    Swal.fire({
      text: "Selesaikan Acara ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const formData: any = {
          asset_reservation_id: id,
          status: 3,
        }

        console.log(formData);
        this.service.doAction(formData).subscribe({
          next: (res) => {
            this.loadData();
            Swal.fire({
              text: "Berhasil diselesaikan",
              icon: "success",
            });
          },
          error: (err) => { console.log(err); },
        });
      }
    });
  }
}
