import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { env } from "@env/env";

@Injectable({
  providedIn: "root"
})
export class UserService {

  constructor(
    private http: HttpClient,
    private stgService: StorageService,
  ) { }

  public getUserInfo(): Observable<any> {
    const userData = this.stgService.getUser(true);
    const token = userData?.data?.token;

    if (!token) {
      return of(false);
    }

    const url = `${env.v1_API}user-info`;

    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      responseType: "json",
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  public verifySSO(pptAccessKey: string): Observable<any> {
    const requestBody = { ppt_access_key: pptAccessKey };
    return this.http.post(env.v1_API + "verify-session", requestBody, {
      headers: this.getHeaders(),
    });
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getUserInfo().pipe(
      map(userInfo => userInfo.status === 200 && userInfo.data !== null),
      catchError(() => of(false))
    );
  }
}
