import { Component, OnInit } from "@angular/core";
import { Subject, debounceTime, distinctUntilChanged, switchMap, Observable, of } from "rxjs";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from "@services/loading.service";
import { CrudService } from "@services/crud.service";
import { SwalService } from "@services/swal.service";
import { catchError } from "rxjs/operators";
import { Location } from '@angular/common';
import Swal from "sweetalert2";
import { DetailAssetPublicationComponent } from './detail-asset-publication/detail-asset-publication.component';

@Component({
  selector: "app-asset-publication",
  templateUrl: "./asset-publication.component.html",
  styleUrls: ["./asset-publication.component.scss"]
})
export class AssetPublicationComponent implements OnInit {
  private readonly FIELD = "asset-publication";
  private searchTerms = new Subject<string>();
  private totalRecords: number = 0;
  private sortedColumnIndex: number | null = null;
  private selectedIds: string[] = [];
  protected module_title: string = "Publikasi Asset";
  protected data: any[] = [];
  protected filter: any = {
    search: "", sortBy: "id", sortOrder: "desc", page: 1, pageSize: 10,
  };
  protected currentPage: number = 1;
  protected pageSize: number = 10;
  protected totalPages: number = 0;
  protected selectedPageSize: number = 10;
  protected sortE: string = this.service.e.sort;
  protected isDelete: boolean = false;
  protected visible: boolean = false;
  protected action: string = "";
  protected showSkeleton: boolean = true;

  constructor(
    private modalService: NgbModal,
    private service: CrudService,
    protected loadingService: LoadingService,
    private sW: SwalService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.search(term)),
      )
      .subscribe();

    this.service.visibleChanged.subscribe((value) => {
      this.visible = value;
    });

    this.service.getLabel(1).subscribe((label) => {
      this.action = label;
    });

    this.service.loadData$.subscribe(() => {
      this.loadData();
    });

    this.loadingService.showLoading();
    this.loadData();

    setTimeout(() => {
      this.showSkeleton = false;
    }, 1000);
  }

  goBack(): void {
    this.location.back();
  }

  private loadData(): void {
    this.loadingService.showLoading();
    this.service.getListRS(this.FIELD, this.filter).subscribe({
      next: (res) => {
        console.log(res);
        this.data = res.data.rows;
        this.totalRecords = res.data.meta.total;
        this.calculateTotalPages();
        this.data.forEach(item => {
          this.getReservationCount(item.id).subscribe(total => {
            item.reservationTotal = total; // Save total to the item
          });
        });
        this.loadingService.hideLoading();
      },
      error: (err) => { console.log(err); },
    });
  }

  openRoomModal(dt: any): void {
    const modalRef: NgbModalRef = this.modalService.open(DetailAssetPublicationComponent, {
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.publicationName = dt.asset_name;
    modalRef.componentInstance.publicationID = dt.id;
    modalRef.componentInstance.modal = modalRef;

    modalRef.componentInstance.reserved.subscribe(() => {
      this.loadData();  // Trigger loadData when the event is emitted
    });
  }

  reloadPage(): void {
    window.location.reload();
  }

  getReservationCount(itemId: string): Observable<number> {
    return this.service.getReservationTotal(itemId).pipe(
      switchMap((res: any) => {
        if (res?.status === 200 && res?.data?.total !== undefined) {
          return of(res.data.total); // Return the total count
        }
        return of(0); // Fallback to 0 if no count is available
      }),
      catchError(() => of(0)) // Handle any errors
    );
  }

  protected calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
  }

  protected onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.filter.page = newPage;
    this.loadData();
  }

  protected onPageSizeChange(newPageSize: number): void {
    this.pageSize = newPageSize;
    this.currentPage = 1;
    this.filter.page = this.currentPage;
    this.filter.pageSize = newPageSize;
    this.loadData();
  }

  protected getPagesArray(): number[] {
    const totalVisiblePages = 5;
    const pagesArray: number[] = [];
    const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2));
    const endPage = Math.min(this.totalPages, startPage + totalVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pagesArray.push(i);
    }

    return pagesArray;
  }

  protected sort(columnIndex: number, str: string): void {
    if (this.filter.sortBy === str) {
      this.filter.sortOrder = this.filter.sortOrder === "asc" ? "desc" : "asc";
    } else {
      this.filter.sortOrder = "desc";
      this.filter.sortBy = str;
    }
    this.sortedColumnIndex = columnIndex;
    this.loadData();
  }

  protected changePageSize(): void {
    this.filter.pageSize = this.selectedPageSize;
    this.loadData();
  }

  protected resetIDs(): void {
    this.isDelete = false;
    this.selectedIds = [];
  }

  protected doCreate(): void {
    this.resetIDs();
    this.service.setID(1, null);
    this.service.setLabel(1, "Data Baru");
    this.service.toggleVisible(1);
  }

  protected doEdit(id: string) {
    this.resetIDs();
    this.service.setLabel(1, "Edit Data");
    this.service.setID(1, id);
    this.service.toggleVisible(1);
  }

  protected isSorted(columnIndex: number): boolean {
    return this.sortedColumnIndex === columnIndex;
  }

  protected search(term: string): Observable<any[]> {
    if (term.length <= 0 || term.length >= 3) {
      this.loadingService.showLoading();
      this.filter.search = term.toLocaleLowerCase();
      this.loadData();
    }

    return of([]);
  }

  protected onSearchInput(event: any): void {
    const term = event.target.value.trim();
    this.loadingService.showLoading();
    this.currentPage = 1;
    this.filter.page = 1;
    this.searchTerms.next(term);
  }

  protected onCheckboxChange(id: string, name: string): void {
    const index = this.selectedIds.indexOf(id);
    if (index === -1) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds.splice(index, 1);
    }

    this.isDelete = this.selectedIds.length > 0;
  }

  protected isSelected(id: string): boolean {
    return this.selectedIds.includes(id);
  }

  protected doDelete(id: string): void {
    Swal.fire({
      title: "Hapus Data ?",
      text: "Yakin hapus data yang anda pilih?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteDataIDRS(this.FIELD, id).subscribe({
          next: () => {
            this.loadData();
          },
          error: (err) => {
            console.error(err);
          },
        });
      }
    });
  }
}
