import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from "../../_services/crud.service";

@Component({
  selector: 'app-redirect-url',
  templateUrl: './redirect-url.component.html',
  styleUrls: ['./redirect-url.component.scss']
})
export class RedirectUrlComponent {
  protected endpoint: string;

  constructor(
    private route: ActivatedRoute,
    private service: CrudService,
    private router: Router,
  ) {
    this.endpoint = this.route.snapshot.params.endpoint;
    this.service.isAccess("url-shortener", this.endpoint).subscribe({
      next: (res) => {
        if (res.status === 200 && res.data.url) {
          window.location.href = res.data.url;
        } else {
          this.router.navigate(['/']);
        }
      },
      error: (err) => {
        console.error(err);
        this.router.navigate(['/']);
      }
    });
  }
}
