import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from "@services/crud.service";
import Swal from 'sweetalert2';
import { env } from "@env/env";

@Component({
  selector: 'app-detail-asset-publication',
  templateUrl: './detail-asset-publication.component.html',
  styleUrls: ['./detail-asset-publication.component.scss']
})
export class DetailAssetPublicationComponent implements OnInit {
  @Input() publicationName: string;
  @Input() publicationID: string;
  @Input() modal: NgbModalRef;
  @Output() reserved = new EventEmitter<void>();
  data: any = {};
  protected assetURL: string = env.asset_API;

  constructor(private crud: CrudService) {

  }

  ngOnInit(): void {
    this.loadDetail();
  }

  reserveRoom(): void {
    this.modal.dismiss();
    this.reserved.emit();
  }

  private loadDetail(): void {
    this.crud.getReservation(this.publicationID, "other").subscribe({
      next: (res) => {
        console.log(res);
        if (res.status === 200) {
          this.data = res.data;
        } else {
          console.log(res.message);
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  acceptReservation(id: string): void {
    Swal.fire({
      title: "Terima Reservasi?",
      text: "Apakah Anda yakin ingin menerima reservasi ini?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Terima",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        const formData: any = {
          asset_reservation_id: id,
          status: 2,
        }
        this.crud.doAction(formData).subscribe({
          next: (res) => {
            this.loadDetail();
            Swal.fire({
              text: "Reservasi diterima",
              icon: "success",
            });
            this.modal.dismiss();
            this.reserved.emit();
          },
          error: (err) => { console.log(err); },
        });
      }
    });
  }

  rejectReservation(id: string): void {
    Swal.fire({
      title: "Tolak Reservasi",
      text: "Masukkan alasan penolakan:",
      icon: "warning",
      input: 'textarea',
      inputPlaceholder: 'Masukkan alasan...',
      inputAttributes: {
        'aria-label': 'Masukkan alasan penolakan'
      },
      showCancelButton: true,
      confirmButtonText: "Tolak",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          const formData: any = {
            asset_reservation_id: id,
            status: 4,
            reason: `${result.value}`
          }
          this.crud.doAction(formData).subscribe({
            next: (res) => {
              this.loadDetail();
              Swal.fire({
                text: `Reservasi ditolak dengan alasan: ${result.value}`,
                icon: "success",
              });
              this.modal.dismiss();
              this.reserved.emit();
            },
            error: (err) => { console.log(err); },
          });
        } else {
          Swal.fire({
            text: "Alasan penolakan tidak boleh kosong",
            icon: "warning",
          });
        }
      }
    });
  }

  // Dummy action for successfully rejecting a reservation
  rejectSuccessfully(): void {
    Swal.fire({
      text: "Reservasi berhasil ditolak",
      icon: "info",
    });
    // Add any additional logic to handle successful rejection here
  }

  close(): void {
    this.modal.dismiss();
  }
}
