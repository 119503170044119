<div class="col-lg-12 mb-3" style="padding: 10px; background-color: rgb(236, 244, 244)">
  <div class="ribbon-wrapper-right card">
    <div class="card-body">
      <div class="ribbon ribbon-bookmark ribbon-success fw-bold">Create/Update</div>
      <button class="ribbon ribbon-clip-right ribbon-right ribbon-primary rounded" (click)="doSave()">Simpan</button>
      <button class="ribbon ribbon-clip-bottom-right ribbon-danger rounded" (click)="doCancel()">Batal</button>
      <form style="margin-bottom: 10px" [formGroup]="formData">
        <div class="row">
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Image <small class="font-warning"><i>* wajib</i></small></label>
            <div class="input-group">
              <input class="form-control bg-light-primary" type="file" name="image" [value]="" formControlName="image"
                (change)="onFileSelected($event)"
                accept=".png, .jpg, .jpeg"/>
            </div>
            <img *ngIf="previewImage" [src]="previewImage" alt="Preview Image"
              style="max-width: 200px; margin-top: 5px;">
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Name</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input class="form-control gf_border bg-light-success" type="text" placeholder="Name" name="name" formControlName="name" />
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Description</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input class="form-control gf_border bg-light-success" type="text" placeholder="Description" name="description"
                formControlName="description" />
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-6">
            <label class="form-label">Unit Kerja</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <select class="form-control gf_border bg-light-success" formControlName="kode_unker">
                <option *ngFor="let unit of unker" [value]="unit.kode_unker">
                  {{ unit.nama_unker }}
                </option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-6">
            <label class="form-label">Kapasitas</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input class="form-control gf_border bg-light-success" type="number" placeholder="Kapasitas" name="available_qty"
                formControlName="available_qty" />
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4 d-none">
            <label class="form-label">Total Ketersediaan Jam / Hari</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input class="form-control gf_border bg-light-success" type="number" placeholder="Total Ketersediaan Jam" name="available_total_time"
                formControlName="available_total_time" />
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Provinsi</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select formControlName="province" class="form-control gf_border bg-light-info brtr-15" (change)="onRegionSelected('prov', '2', $event.target.value)">
                <option [value]="" selected="true">Provinsi</option>
                <option *ngFor="let item of provinsiData" [value]="item.kode">{{ item.nama }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Kota/Kabupaten</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select formControlName="city" class="form-control gf_border bg-light-info brtr-15" (change)="onRegionSelected('kab', '3', $event.target.value)">
                <option [value]="" selected="true">Kota/Kabupaten</option>
                <option *ngFor="let item of kabupatenData" [value]="item.kode">{{ item.nama }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Kecamatan</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select formControlName="subdistrict" class="form-control gf_border bg-light-info brtr-15" (change)="onRegionSelected('kec', '4', $event.target.value)">
                <option [value]="" selected="true">Kecamatan</option>
                <option *ngFor="let item of kecamatanData" [value]="item.kode">{{ item.nama }}</option>
              </select>
            </div>
          </div>
          <hr/>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Filter Lokasi</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select class="form-control gf_border bg-light-success" formControlName="location">
                <option [value]="" selected="true">Pilih Lokasi</option>
                <option *ngFor="let loca of locationData" [value]="loca.id">
                  {{ loca.name.toUpperCase() }}
                </option>
              </select>
              <button class="ribbon ribbon-clip-right ribbon-right ribbon-danger rounded" style="z-index: 99999;" (click)="doDeleteLocation()"><i class="fa fa-minus"></i></button>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Tambah Lokasi</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input
                class="form-control gf_border bg-light-success"
                type="text"
                placeholder="Lokasi"
                name="location_input"
                formControlName="location_input"
                (input)="onLocationInputChange($event)"
              />
              <button
                class="ribbon ribbon-clip-right ribbon-right ribbon-primary rounded"
                style="z-index: 99999;"
                (click)="doAddLocation()"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>