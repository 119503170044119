<div [ngClass]="layout.config.settings.layout_type">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="sidebar-wrapper" [attr.icon]="layout.config.settings.icon"
        [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body" [style.marginLeft]="isAdmin ? null : '0'" [style.width]="isAdmin ? null : '100%'">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
    </div>
    <app-floating-menu></app-floating-menu>
  </div>
</div>
<div class="bg-overlay1"></div>