<div class="card shadow mt-4">
  <div class="card-body custom-datatable noscroll">
    <div class="custom-datatable">
      <div class="card shadow">
        <div class="card-body custom-datatable noscroll row">
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Unit Kerja</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select class="form-control gf_border" (change)="onUnkerChange($event.target.value)">
                <option [value]="" selected="true">Unit Kerja</option>
                <option *ngFor="let unit of unker" [value]="unit.kode_unker">{{ unit.prefix }} - {{ unit.nama_unker }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Lokasi</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select class="form-control gf_border" (change)="onLocationChange($event.target.value)">
                <option [value]="" selected="true">Lokasi</option>
                <option *ngFor="let loca of location" [value]="loca.id">{{ loca.name }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Provinsi</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select class="form-control gf_border bg-light-info brtr-15" (change)="onRegionSelected('prov', '2', $event.target.value)">
                <option [value]="" selected="true">Provinsi</option>
                <option *ngFor="let item of provinsiData" [value]="item.kode">{{ item.nama }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Kota/Kabupaten</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select class="form-control gf_border bg-light-info brtr-15" (change)="onRegionSelected('kab', '3', $event.target.value)">
                <option [value]="" selected="true">Kota/Kabupaten</option>
                <option *ngFor="let item of kabupatenData" [value]="item.kode">{{ item.nama }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4">
            <label class="form-label">Kecamatan</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <select class="form-control gf_border bg-light-info brtr-15" (change)="onRegionSelected('kec', '4', $event.target.value)">
                <option [value]="" selected="true">Kecamatan</option>
                <option *ngFor="let item of kecamatanData" [value]="item.kode">{{ item.nama }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body mt-4 mb-2">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="row mb-3 d-flex justify-content-center">
              <div *ngFor="let room of rooms | paginate: { itemsPerPage: 10, currentPage: page }"
                class="col-lg-2 col-4 user-image bg-card-2 mb-3">
                <div class="image-container" (click)="openRoomModal(room)">
                  <img height="100" class="top-right-img" [src]="assetURL + room.image" alt="{{ room.name }}">
                  <div class="text-centered text-white">
                    <h4 class="st-1 lh">{{ room.name }}</h4>
                  </div>
                  <span class="st-2 st-2-label lh">{{ room.nama_unker }}</span>
                  <span class="st-2 st-3-label lh">{{ room.kecamatan }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-centered">
        <pagination-controls (pageChange)="changePage($event)"></pagination-controls>
      </div>
    </div>
  </div>
</div>