import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CrudService } from "../../_services/crud.service";
import { UserService } from "../../_services/user.service";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "../../_services/modal.service";
import { Router } from '@angular/router';
import Swal from "sweetalert2";

@Component({
  selector: "app-url-shortener",
  templateUrl: "./url-shortener.component.html",
  styleUrls: ["./url-shortener.component.scss"]
})
export class UrlShortenerComponent {
  protected urlForm: FormGroup;
  protected baseUrl: string = "";
  protected endpoint: string = "";
  protected isLoggedIn: boolean = false;
  protected slug: string = "url-shortener";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private crudService: CrudService,
    private toast: ToastrService,
    private modalService: ModalService,
    private usrService: UserService,
    private router: Router,
  ) {
    this.baseUrl = this.document.location.origin;
    this.usrService.isLoggedIn().subscribe((r) => {
      if (r) {
        this.crudService.checkModuleAccess(this.slug).subscribe((res) => {
          if (!res.data) { this.router.navigate(['/']); }
        });
      }
    });

    this.urlForm = this.formBuilder.group({
      id: [null],
      url: [null, [
        Validators.required,
        Validators.minLength(4)
      ]],
      description: [null],
      endpoint: [null],
      expired: [null, [
        Validators.required
      ]],
    });
  }

  protected onEndpointKeyUp() {
    this.endpoint = this.endpoint.replace(/[^a-zA-Z0-9]/g, "-");
  }

  protected onEndpointChange() {
    this.crudService.isExist("url-shortener", "endpoint", this.endpoint).subscribe({
      next: (res)=> {
        if (res.data) {
          this.endpoint = "";
          this.toast.warning('Endpoint sudah dipakai, silahkan diganti', 'Warning');
        }
      }
    });
  }

  protected doGenerate(): void {
    if (this.urlForm.invalid) {
      Swal.fire({
        text: "Silahkan input semua form yang diwajibkan isi sebelum menyimpan.",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      text: "Generate URL ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const formData: any = {
          url: this.urlForm.get("url")?.value,
          description: this.urlForm.get("description")?.value,
          endpoint: this.urlForm.get("endpoint")?.value,
          expired: this.urlForm.get("expired")?.value,
        };
        this.crudService.createData("url-shortener", formData).subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.toast.success('URL successfully shortened!', 'Success');
              this.urlForm.reset();
              this.endpoint = '';
              this.modalService.triggerOpenModal();
            }
          },
          error: (err) => {
            console.log(err);
          }
        });
      }
    });
  }
}
