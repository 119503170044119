import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import Swal from "sweetalert2";
import { env } from "@env/env";

@Injectable({
  providedIn: "root",
})
export class MainService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  private getRSHeaders(): HttpHeaders {
    const dataKey = this.cookieService.get("ppt-asset-data-key");
    return new HttpHeaders({
      Authorization: `data-key ${dataKey}`,
    });
  }

  getList(level: string, parentCode: string): Observable<any> {
    const url = `${env.v1_API}region-list/${level}/${parentCode}`;

    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "json",
    });
  }

  getAssetList(): Observable<any> {
    const url = `${env.rs_API}get-asset-list`;
    return this.http.get(url, {
      headers: this.getRSHeaders(),
      responseType: "json",
    });
  }

  getAssetListDetail(asset_id: string): Observable<any> {
    const url = `${env.rs_API}get-asset-list-detail/${asset_id}`;
    return this.http.get(url, {
      headers: this.getRSHeaders(),
      responseType: "json",
    });
  }
}