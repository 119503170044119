import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CrudService } from "@services/crud.service";
import Swal from "sweetalert2";
import SignaturePad from "signature_pad";

@Component({
  selector: "app-asset-attendance",
  templateUrl: "./asset-attendance.component.html",
  styleUrls: ["./asset-attendance.component.scss"]
})
export class AssetAttendanceComponent implements OnInit, AfterViewChecked {
  @ViewChild("canvas", { static: false }) canvas: ElementRef;
  sig: SignaturePad;
  attendanceForm: FormGroup;
  protected attendanceID: string;
  protected issig: number;
  canvasInitialized = false;

  constructor(
    private crud: CrudService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.attendanceID = this.route.snapshot.params['id'];
    this.route.queryParams.subscribe(params => {
      this.issig = params['signature'];
    });
    this.attendanceForm = this.fb.group({
      reservation_id: [this.attendanceID, Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      email: [''],
      phone: [''],
      signature: ['']
    });
  }

  ngAfterViewChecked(): void {
    // Check if the signature is needed and if the canvas hasn't been initialized yet
    if (this.issig == 1 && this.canvas && !this.canvasInitialized) {
      this.initializeSignaturePad();
    }
    // Manually detect changes if necessary
    this.cdRef.detectChanges();
  }

  initializeSignaturePad(): void {
    this.sig = new SignaturePad(this.canvas.nativeElement);
    this.canvasInitialized = true; // Set flag to prevent re-initialization
  }

  saveSignature() {
    if (this.sig.isEmpty()) {
      Swal.fire('Error', 'Please provide a signature', 'error');
      return;
    }

    const signatureDataUrl = this.sig.toDataURL("image/png");
    this.attendanceForm.patchValue({
      signature: signatureDataUrl
    });

  }

  clear() {
    this.sig.clear();
  }

  onSubmit(): void {
    if (this.issig == 1) {
      this.saveSignature();
    }

    if (this.attendanceForm.valid) {
      const formData = {
        ...this.attendanceForm.value,
        phone: '0' + this.attendanceForm.get('phone')?.value.toString()
      };
      Swal.fire({
        text: "Kirim Absensi sekarang ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.crud.doAbsence(formData).subscribe({
            next: (res) => {
              if (res.status == 200) {
                Swal.fire({
                  text: "Absensi berhasil dikirim",
                  icon: "success",
                }).then(() => {
                  this.attendanceForm.reset();
                  this.sig.clear();
                  setTimeout(() => {
                    if (window.opener) {
                      window.close();
                    } else {
                      window.location.href = "/dashboard";
                    }
                  }, 1000);
                });
              } else {
                Swal.fire({
                  text: res.message,
                  icon: "warning",
                });
              }
            },
            error: (err) => {
              Swal.fire({
                text: err.error?.error || "An unexpected error occurred",
                icon: "warning",
              });
            },
          });
        }
      });
    }
  }
}
