<div class="modal-header">
  <h4 class="modal-title"><i class="fa fa-edit"></i> {{ assetName }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card-body">
    <form [formGroup]="formData">
      <div class="row">
        <div class="mb-3 m-form__group col-lg-3">
          <label class="form-label">Name</label>
          <div class="input-group">
            <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
            <input class="form-control gf_border" type="text" placeholder="Name"
              name="name"
              formControlName="name" />
          </div>
        </div>
        <div class="mb-3 m-form__group col-lg-3">
          <label class="form-label">Description</label>
          <div class="input-group">
            <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
            <input class="form-control gf_border" type="text" placeholder="Description"
              name="description"
              formControlName="description" />
          </div>
        </div>
        <div class="mb-3 m-form__group col-lg-2">
          <label class="form-label">Qty</label>
          <div class="input-group">
            <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
            <input class="form-control gf_border" type="number" placeholder="Qty"
              name="qty"
              formControlName="qty" />
          </div>
        </div>
        <div class="mb-3 m-form__group col-lg-3">
          <label class="form-label">Unit Kerja</label>
          <div class="input-group">
            <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
            <select class="form-control gf_border" formControlName="kode_unker">
              <option *ngFor="let unit of unker" [value]="unit.kode_unker">
                {{ unit.nama_unker }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3 m-form__group col-lg-1 d-flex align-items-center">
          <button class="btn btn-md btn-primary rounded mt-4" (click)="doSave()"><i class="fa fa-plus"></i></button>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead class="bg-primary">
        <tr>
          <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(0, 'id')">
            No <i [innerHTML]="sortE" [class.text-warning]="isSorted(0)"></i>
          </th>
          <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(1, 'name')">
            Name <i [innerHTML]="sortE" [class.text-warning]="isSorted(1)"></i>
          </th>
          <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(2, 'description')">
            Description <i [innerHTML]="sortE" [class.text-warning]="isSorted(2)"></i>
          </th>
          <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(3, 'qty')">
            Qty <i [innerHTML]="sortE" [class.text-warning]="isSorted(3)"></i>
          </th>
          <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(4, 'kode_unker')">
            Unit Kerja <i [innerHTML]="sortE" [class.text-warning]="isSorted(4)"></i>
          </th>
          <th scope="col" *ngIf="!visible">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; let i = index">
          <td>
            {{ (currentPage - 1) * pageSize + i + 1 }}
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.description }}
          </td>
          <td>
            {{ item.qty }}
          </td>
          <td>
            {{ item.nama_unker }}
          </td>
          <td *ngIf="!visible">
            <div class="action">
              <li (click)="doDelete(item.id)" class="delete badge badge-md badge-danger d-flex align-items-center">
                <i class="fa fa-trash text-white"></i>
              </li>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between mt-3 mb-3">
    <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="selectedPageSize"
      (change)="changePageSize()">
      <option value="10">10 data</option>
    </select>
    <nav aria-label="...">
      <ul class="pagination pagination-primary">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" href="javascript:void(0)" (click)="onPageChange(1)">First</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === 1">
          <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage - 1)">Prev</a>
        </li>

        <li class="page-item" *ngFor="let page of getPagesArray(); let i = index"
          [class.active]="currentPage === page">
          <a class="page-link" href="javascript:void(0)" (click)="onPageChange(page)">{{ page }}</a>
        </li>

        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage + 1)">Next</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <a class="page-link" href="javascript:void(0)" (click)="onPageChange(totalPages)">Last</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
