import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-geometryutil';
import { latLng, tileLayer } from 'leaflet';
import { PolygonService } from './polygon.service';
import { Router, ActivatedRoute } from '@angular/router';

type LatLngTuple = [number, number];

interface PolygonData {
  id: number;
  pol_cat_id: number;
  user_id: number;
  code: string;
  metadata: { [key: string]: any };
  latlong: LatLngTuple[]; // Single polygon coordinates
  areaSizeSqMeters: number;
  areaSizeHectares: number;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'app-polygon',
  templateUrl: './polygon.component.html',
  styleUrls: ['./polygon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PolygonComponent implements OnInit {
  protected title: string = "Polygon";
  protected description: string = "Polygon Description";
  protected id: string;
  protected uid: string;
  protected polygonsData: PolygonData[] = [];
  protected map: L.Map;
  protected options: L.MapOptions;
  protected zoom: number;
  protected bounds: L.LatLngBounds;

  constructor(
    private polService: PolygonService,
    private router: Router,
    private routeInfo: ActivatedRoute
  ) { }

  ngOnInit() {
    // Initialize the map options
    this.options = {
      layers: [
        tileLayer(
          'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            attribution: '© OpenStreetMap contributors',
          }
        ),
      ],
      zoom: 5,
      center: latLng(-2.5489, 118.0149), // Centered on Indonesia
    };

    this.zoom = 5;
    this.bounds = new L.LatLngBounds(
      latLng(-11.0, 94.0),
      latLng(6.0, 141.0)
    );

    this.routeInfo.paramMap.subscribe((params) => {
      this.id = params.get("id") || '';
      this.uid = params.get("uid") || '';
      this.loadPolygons();
    });
  }

  loadPolygons() {
    this.polService.getData(this.id, this.uid).subscribe({
      next: (res) => {
        this.polygonsData = res.data.polygon;
        this.title = res.data.category.title;
        this.description = res.data.category.description;
        if (this.map) {
          this.displayPolygonsOnMap();
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onMapReady(map: L.Map) {
    this.map = map;
    if (this.polygonsData.length > 0) {
      this.displayPolygonsOnMap();
    }
  }

  displayPolygonsOnMap() {
    if (!this.map) {
      return;
    }

    const allBounds = new L.LatLngBounds([]);

    this.polygonsData.forEach((polygonData) => {
      const latlong = polygonData.latlong as LatLngTuple[];
      if (latlong && latlong.length > 0) {
        const polygonLayer = L.polygon(latlong, {
          color: '#3ec75f',
          fillColor: '#20e351',
          fillOpacity: 0.4,
        });

        polygonLayer.addTo(this.map);
        allBounds.extend(polygonLayer.getBounds());

        // Check if metadata contains 'title'
        const metadata = polygonData.metadata || {};
        const title = metadata.title || metadata.Title || metadata.Judul; // Handle possible key variations

        if (title) {
          // Calculate the centroid of the polygon
          const centroid = polygonLayer.getCenter();

          // Create a custom divIcon to display the title
          const titleIcon = L.divIcon({
            className: 'polygon-title-label', // Custom CSS class
            html: `<div>${title}</div>`,
            iconSize: [100, 30], // Adjusted size to fit larger text
            iconAnchor: [50, 15], // Center the icon
          });

          // Place the marker at the centroid
          const titleMarker = L.marker(centroid, {
            icon: titleIcon,
            interactive: false, // Make the marker non-interactive
          });

          // Add the marker to the map
          titleMarker.addTo(this.map);
        }

        // Add event listeners to the polygon
        polygonLayer.on('click', () => {
          this.onPolygonClick(polygonData, polygonLayer);
        });

        polygonLayer.on('mouseover', () => {
          this.onPolygonMouseOver(polygonData, polygonLayer);
        });

        polygonLayer.on('mouseout', () => {
          this.onPolygonMouseOut(polygonLayer);
        });
      }
    });

    if (allBounds.isValid()) {
      this.map.fitBounds(allBounds, { maxZoom: 6 });
    } else {
      // Default to Indonesia bounds
      this.map.fitBounds(this.bounds);
    }
  }

  onPolygonClick(polygonData: PolygonData, polygonLayer: L.Polygon) {
    // Prepare the content for the popup
    const content = this.generatePopupContent(polygonData);

    // Bind the popup to the polygon and open it
    polygonLayer.bindPopup(content).openPopup();
  }

  onPolygonMouseOver(polygonData: PolygonData, polygonLayer: L.Polygon) {
    // Prepare the content for the tooltip
    const content = this.generatePopupContent(polygonData);

    // Bind the tooltip to the polygon and open it
    polygonLayer.bindTooltip(content, { sticky: true }).openTooltip();
  }

  onPolygonMouseOut(polygonLayer: L.Polygon) {
    // Close the tooltip when the mouse leaves the polygon
    polygonLayer.closeTooltip();
  }

  generatePopupContent(polygonData: PolygonData): string {
    const metadata = polygonData.metadata || {};
    const areaSizeSqMeters = polygonData.areaSizeSqMeters || 0;
    const areaSizeHectares = polygonData.areaSizeHectares || 0;

    // If area sizes are zero, calculate them
    let areaSqMeters = areaSizeSqMeters;
    let areaHectares = areaSizeHectares;

    if (areaSqMeters === 0 || areaHectares === 0) {
      // Calculate the area of the polygon
      const latLngs = polygonData.latlong.map((coord: LatLngTuple) => L.latLng(coord[0], coord[1]));
      areaSqMeters = L.GeometryUtil.geodesicArea(latLngs);
      areaHectares = areaSqMeters / 10000;
    }

    // Build the content HTML
    let content = '<div>';
    content += '<ul>';

    // Prioritize 'title' or 'judul' keys
    const prioritizedKeys = ['title', 'Title', 'judul', 'Judul']; // Add variations as needed

    prioritizedKeys.forEach((key) => {
      if (metadata.hasOwnProperty(key)) {
        content += `<li><strong>${this.capitalizeFirstLetter(key)}:</strong> ${metadata[key]}</li>`;
      }
    });

    // Display the remaining metadata keys (excluding prioritized ones)
    for (const key in metadata) {
      if (metadata.hasOwnProperty(key) && !prioritizedKeys.includes(key)) {
        content += `<li><strong>${this.capitalizeFirstLetter(key)}:</strong> ${metadata[key]}</li>`;
      }
    }

    // Display area sizes
    content += `<li><strong>Luas Area (m2):</strong> ${areaSqMeters.toFixed(2)}</li>`;
    content += `<li><strong>Luas Area (hektar):</strong> ${areaHectares.toFixed(4)}</li>`;
    content += '</ul>';
    content += '</div>';

    return content;
  }

  capitalizeFirstLetter(string: string): string {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
