import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
  OnInit
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  isBefore
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarDateFormatter,
  CalendarUtils,
  CalendarA11y
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { CrudService } from "@services/crud.service";

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#006600',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
  ],
  providers: [
    CalendarDateFormatter,
    CalendarUtils,
    CalendarA11y
  ]
})
export class CalendarComponent implements OnInit {
  @Input() pubID: string;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  ngOnInit(): void {
    console.log(this.pubID);
    this.loadEvents();  // Call to load events on initialization
  }

  private loadEvents(): void {
    this.crud.getAttendanceCalendar(parseInt(this.pubID)).subscribe({
      next: (response) => {
        console.log("Loaded data:", response);

        // Check if response has the expected structure
        if (response && Array.isArray(response.data) && response.data.length > 0) {
          this.events = response.data.map(eventArray => {
            const start = new Date(`${eventArray[0]}T${eventArray[1]}`); // Construct start date
            const end = new Date(`${eventArray[0]}T${eventArray[2]}`);   // Construct end date
            const title = `Direservasi jam ${eventArray[1]} - ${eventArray[2]}`; // Construct title

            return {
              start: start,
              end: end,
              title: title,
              color: { ...colors.yellow }, // Set event color to yellow
              actions: this.actions,
              allDay: false, // Set to false if these are not all-day events
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              draggable: true,
            };
          });
        } else {
          console.error('Expected an array but got:', response.data);
        }

        this.refresh.next();  // Trigger refresh
      },
      error: (err) => {
        console.error('Error loading events:', err);
      }
    });
  }

  refresh = new Subject<void>();

  events: CalendarEvent[] = [
  ];

  activeDayIsOpen: boolean = false;

  constructor(
    private modal: NgbModal,
    private crud: CrudService
  ) { }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const today = new Date();

    if (isBefore(date, startOfDay(today))) {
      // If the clicked date is before today, do nothing
      return;
    }

    // Existing logic for handling date clicks
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
