const buttonAct = [
  {
    classes: "shepherd-button-grey",
    text: "Exit",
    type: "cancel"
  },
  {
    classes: "shepherd-button-light-primary",
    text: "Sebelum",
    type: "back"
  },
  {
    classes: "shepherd-button-primary",
    text: "Berikutnya",
    type: "next"
  }
];

export const stepsAuth: any = [
  {
    id: "userTour",
    attachTo: {
      element: ".user-tour",
      on: "bottom"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi User",
    text: ["Jika disorot maka user dapat melihat sisa waktu sesi login beserta menu untuk menambah sesi (refresh) serta logout"],
  },
  {
    id: "secondTour",
    attachTo: {
      element: ".second-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi dan Titik Lokasi",
    text: ["Jika user berhasil login maka informasi akan ditampilkan disini beserta titik lokasi berdasarkan GPS"],
  },
  {
    id: "thirdTour",
    attachTo: {
      element: ".third-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi Cuaca",
    text: ["Informasi cuaca, kemembaban udara, tekanan udara, arah angin yang didapat berdasarkan titik lokasi GPS melalui sumber API OpenWeatherMap"],
  },
  {
    id: "fourTour",
    attachTo: {
      element: ".four-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 100);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Layanan Portal Pertanian Terintegrasi",
    text: ["Daftar layanan pada Portal Pertanian Terintegrasi yang dapat difilter berdasarkan kategori dan kolom pencarian"],
  },
  {
    id: "fiveTour",
    attachTo: {
      element: ".five-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 150);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Kategori Layanan",
    text: ["Jika diklik maka layanan akan ditampilkan berdasarkan kategori tersebut, klik kembali untuk menonaktifkan filter"],
  },
  {
    id: "sixTour",
    attachTo: {
      element: ".six-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 150);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Pencarian Layanan",
    text: ["Kolom untuk melakukan pencarian layanan berdasarkan deskripsi layanan yang tersedia"],
  },
  {
    id: "sevenTour",
    attachTo: {
      element: ".seven-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 300);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Layanan Utama",
    text: ["Jika diklik maka akan menampilkan detail layanan yang dituju"],
  },
  {
    id: "seven1Tour",
    attachTo: {
      element: ".seven1-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 700);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Layanan Berikutnya",
    text: ["Jika diklik maka akan menampilkan daftar layanan berikutnya jika ada"],
  },
  {
    id: "eightTour",
    attachTo: {
      element: ".eight-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 800);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi Lainnya",
    text: ["Merupakan informasi lainnya yang ditampilkan pada Portal Pertanian Terintegrasi"],
  },
  {
    id: "eight1Tour",
    attachTo: {
      element: ".eight1-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Tombol Home",
    text: ["Jika diklik maka akan kembali ke halaman utama / beranda"],
  },
  {
    id: "profileTour",
    attachTo: {
      element: ".profile-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Tombol Profile",
    text: ["Jika diklik maka akan diarahkan menuju halaman profil user untuk update data atau ganti password"],
  },
  {
    id: "urlTour",
    attachTo: {
      element: ".url-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Tombol List URL",
    text: ["Jika diklik maka akan menampilkan daftar Shorten URL yang telah dibuat sebelumnya"],
  },
  {
    id: "nineTour",
    attachTo: {
      element: ".userway_buttons_wrapper",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Fitur Disabilitas",
    text: ["Fitur yang dapat digunakan untuk membantu memudahkan user disabilitas"],
  }
];

export const steps: any = [
  {
    id: "firstTour",
    attachTo: {
      element: ".first-tour",
      on: "bottom"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Login/Daftar",
    text: ["Digunakan untuk melakukan login atau daftar akun baru"],
  },
  {
    id: "secondTour",
    attachTo: {
      element: ".second-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi dan Titik Lokasi",
    text: ["Jika user berhasil login maka informasi akan ditampilkan disini beserta titik lokasi berdasarkan GPS"],
  },
  {
    id: "thirdTour",
    attachTo: {
      element: ".third-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi Cuaca",
    text: ["Informasi cuaca, kemembaban udara, tekanan udara, arah angin yang didapat berdasarkan titik lokasi GPS melalui sumber API OpenWeatherMap"],
  },
  {
    id: "fourTour",
    attachTo: {
      element: ".four-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 100);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Layanan Portal Pertanian Terintegrasi",
    text: ["Daftar layanan pada Portal Pertanian Terintegrasi yang dapat difilter berdasarkan kategori dan kolom pencarian"],
  },
  {
    id: "fiveTour",
    attachTo: {
      element: ".five-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 150);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Kategori Layanan",
    text: ["Jika diklik maka layanan akan ditampilkan berdasarkan kategori tersebut, klik kembali untuk menonaktifkan filter"],
  },
  {
    id: "sixTour",
    attachTo: {
      element: ".six-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 150);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Pencarian Layanan",
    text: ["Kolom untuk melakukan pencarian layanan berdasarkan deskripsi layanan yang tersedia"],
  },
  {
    id: "sevenTour",
    attachTo: {
      element: ".seven-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 300);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Layanan Utama",
    text: ["Jika diklik maka akan menampilkan detail layanan yang dituju"],
  },
  {
    id: "seven1Tour",
    attachTo: {
      element: ".seven1-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 700);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Layanan Berikutnya",
    text: ["Jika diklik maka akan menampilkan daftar layanan berikutnya jika ada"],
  },
  {
    id: "eightTour",
    attachTo: {
      element: ".eight-tour",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 800);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Informasi Lainnya",
    text: ["Merupakan informasi lainnya yang ditampilkan pada Portal Pertanian Terintegrasi"],
  },
  {
    id: "eight1Tour",
    attachTo: {
      element: ".eight1-tour",
      on: "top"
    },
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Tombol Home",
    text: ["Jika diklik maka akan kembali ke halaman utama / beranda"],
  },
  {
    id: "nineTour",
    attachTo: {
      element: ".userway_buttons_wrapper",
      on: "top"
    },
    beforeShowPromise: () => new Promise<void>(resolve => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    }),
    buttons: buttonAct,
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    title: "Fitur Disabilitas",
    text: ["Fitur yang dapat digunakan untuk membantu memudahkan user disabilitas"],
  }
];
