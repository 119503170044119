<div class="container-fluid mb-4">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body custom-datatable noscroll">
          <div class="custom-datatable">
            <nav class="breadcrumb">
              <a routerLink="'/dashboard'" class="breadcrumb-item">Dashboard</a>
              <span class="breadcrumb-item current">{{ sec1_title }}</span>
            </nav>
            <div class="d-flex justify-content-between">
              <h6 class="modern-ribbon shadow">
                <i class="fa fa-map"></i> {{ sec1_title }}
              </h6>
            </div>
            <br />
            <hr />
            <div class="mb-3 row d-flex justify-content-between">
              <div class="col-lg-6">
                <div class="input-group">
                  <span class="input-group-text"><i class="fa fa-search"></i></span>
                  <input class="form-control bg-light-primary" id="search" type="search" [(ngModel)]="filter.search"
                    (input)="onSearchInput($event)" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="input-group">
                  <select [(ngModel)]="filter.cat_id" class="form-control bg-light-success digits"
                    (change)="onSelected($event.target.value)">
                    <option value="" selected>- Filter Kategori -</option>
                    <ng-container *ngFor="let item of category">
                      <option [value]="item.id"> {{ item.title }} </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-lg-2 d-flex justify-content-end">
                <button [routerLink]="'/admin/polygon/new_data'" class="btn btn-primary fw-bold gf-wrap-text"
                  style="margin-left: 5px">
                  <i class="fa fa-edit"></i> Tambah Data
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead class="bg-primary">
                  <tr>
                    <th class="gf-wrap-text" scope="col">
                      No
                    </th>
                    <th class="gf-wrap-text" scope="col">
                      Kategori
                    </th>
                    <th class="gf-wrap-text" scope="col">
                      Wilayah
                    </th>
                    <th class="gf-wrap-text" scope="col">
                      Metadata
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="loadingService.isLoading$ | async">
                    <td colspan="6" class="text-center">
                      <app-loading-icon></app-loading-icon>
                    </td>
                  </tr>
                  <tr *ngIf="data.length <= 0">
                    <td class="text-center" colspan="6">Data tidak ditemukan</td>
                  </tr>
                  <ng-container *ngIf="!(loadingService.isLoading$ | async)">
                    <tr *ngFor="let item of data; let i = index">
                      <td>
                        {{ (currentPage - 1) * pageSize + i + 1 }}
                      </td>
                      <td>
                        {{ item.category }}
                      </td>
                      <td>
                        {{ item.region }}
                      </td>
                      <td>
                        {{ item.metadata }}
                      </td>
                      <td>
                        <div class="action">
                          <li [routerLink]="'/admin/polygon/'+item.id" class="edit badge badge-sm badge-primary pointer">
                            <i class="icon-pencil text-white"></i> Edit
                          </li>
                          <li class="edit badge badge-sm badge-danger pointer" (click)="doDelete(item.id, item.metadata.title)">
                            <i class="icon-trash text-white"></i> Hapus
                          </li>
                          <a [routerLink]="'/polygon/'+item.cat_id+'/'+item.user_id" target="_blank">
                            <li class="edit badge badge-sm badge-info pointer fw-bold">
                              <i class="icon-search text-white"></i> Preview
                            </li>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between mt-3 mb-3">
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="selectedPageSize"
                (change)="changePageSize()">
                <option value="10">10 data</option>
                <option value="100">100 data</option>
              </select>
              <nav aria-label="...">
                <ul class="pagination pagination-primary">
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(1)">First</a>
                  </li>
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage - 1)">Prev</a>
                  </li>

                  <li class="page-item" *ngFor="let page of getPagesArray(); let i = index"
                    [class.active]="currentPage === page">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(page)">{{ page }}</a>
                  </li>

                  <li class="page-item" [class.disabled]="currentPage === totalPages">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage + 1)">Next</a>
                  </li>
                  <li class="page-item" [class.disabled]="currentPage === totalPages">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(totalPages)">Last</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>