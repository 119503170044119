import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoadingService } from "../../../../_services/loading.service";
import { StorageService } from "../../../../_services/storage.service";
import { CrudService } from "../../../../_services/crud.service";
import { ActionService } from '../../../../_services/action.service';
import { PolygonService } from '../polygon.service';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
// table
import { Subject, debounceTime, distinctUntilChanged, switchMap, Observable, of } from "rxjs";
// table

@Component({
  selector: 'app-polygon-list',
  templateUrl: './polygon-list.component.html',
  styleUrl: './polygon-list.component.scss'
})
export class PolygonListComponent implements OnInit {
  protected sec1_title: string = "Data Polygon";
  protected data: any[] = [];
  protected category: any[] = [];
  protected isEdit: boolean = false;

  // form
  protected formData: FormGroup;
  // form

  // table
  private searchTerms = new Subject<string>();
  private totalRecords: number = 0;
  protected filter: any = {
    search: "",
    cat_id: "",
    page: 1,
    pageSize: 10,
  };
  protected currentPage: number = 1;
  protected pageSize: number = 10;
  protected totalPages: number = 0;
  protected selectedPageSize: number = 10;
  // table

  constructor(
    protected loadingService: LoadingService,
    protected crud: CrudService,
    protected stgService: StorageService,
    private polService: PolygonService,
    private action: ActionService,
    public router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.formData = this.formBuilder.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      code: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.search(term)),
      )
      .subscribe();

    this.loadingService.showLoading();
    this.loadData();

    this.loadCategory();
  }

  loadCategory():  void {
    this.polService.getCategory().subscribe({
      next: (res) => {
        if (res && res.data) {
          this.category = res.data;
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onSelected(id: string) {
    this.filter.cat_id = id;
    this.loadData();
  }

  loadData(): void {
    const user = this.stgService.getUser(true);
    this.loadingService.showLoading();
    this.filter.level = user.data.level_id;
    this.filter.code = user.data.code;
    this.polService.getTable(this.filter).subscribe({
      next: (res) => {
        this.data = res.data.row;
        this.totalRecords = res.data.pagination.TotalRecords;
        this.calculateTotalPages();
        this.loadingService.hideLoading();
      },
      error: (err) => {
        console.log(err);
        this.loadingService.hideLoading();
      },
    });
  }

  doDelete(id: string, name: string): void {
    Swal.fire({
      text: "Hapus area polygon " + name + " ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingService.showLoading();
        this.polService.deleteData(id).subscribe({
          next: () => {
            this.loadingService.hideLoading();
            Swal.fire({ text: "berhasil", icon: "success", });
            this.loadData();
          },
          error: (err) => {
            console.error(err);
            this.loadingService.hideLoading();
          },
        });
      }
    });
  }

  // table
  onSearchInput(event: any): void {
    const term = event.target.value;
    this.loadingService.showLoading();
    this.currentPage = 1;
    this.filter.page = 1;
    this.searchTerms.next(term);
  }

  search(term: string): Observable<any[]> {
    if (term.length <= 0 || term.length >= 3) {
      this.loadingService.showLoading();
      this.filter.search = term.toLocaleLowerCase();
      this.loadData();
    }
    return of([]);
  }

  calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
  }

  changePageSize(): void {
    this.pageSize = this.selectedPageSize;
    this.filter.pageSize = this.selectedPageSize;
    this.loadData();
  }

  onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.filter.page = newPage;
    this.loadData();
  }

  protected getPagesArray(): number[] {
    const totalVisiblePages = 5;
    const pagesArray: number[] = [];
    const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2));
    const endPage = Math.min(this.totalPages, startPage + totalVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pagesArray.push(i);
    }

    return pagesArray;
  }
  // table
}
