<div class="modal-header">
  <h4 class="modal-title">Reservasi : {{ publicationName }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Tanggal Reservasi</th>
            <th>Jam Mulai</th>
            <th>Jam Selesai</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td>
              {{ item.title }} <hr/>
              [PIC]
              {{ item.host.domain }} <br />
              {{ item.host.email }} <br />
              {{ item.host.phone }}
            </td>
            <td>{{ item.reserve_date | date: 'dd MMMM yyyy' }}</td>
            <td>{{ item.start_time }}</td>
            <td>{{ item.end_time }}</td>
            <td>
              <div class="action" *ngIf="item.status === 1">
                <li class="edit badge badge-md badge-success d-flex align-items-center" (click)="acceptReservation(item.id)">
                  Terima
                </li>
                <li class="delete badge badge-md badge-danger d-flex align-items-center" (click)="rejectReservation(item.id)">
                  Tolak
                </li>
              </div>
              <div class="action" *ngIf="item.status === 2">
                <li class="badge badge-md badge-primary d-flex align-items-center">
                  Diterima / Berlangsung
                </li>
              </div>
              <div class="action" *ngIf="item.status === 3">
                <li class="badge badge-md badge-success d-flex align-items-center">
                  Selesai
                </li>
              </div>
              <div class="action" *ngIf="item.status === 4">
                <li class="delete badge badge-md badge-danger d-flex align-items-center" (click)="rejectSuccessfully()">
                  Ditolak
                </li>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
