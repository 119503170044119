<div class="gf-floating-menu">
  <div class="float-radius row col-12">
    <div class="float-hover col">
      <a [routerLink]="'/dashboard'">
        <div class="widget-content eight1-tour">
          <div class="text-center flow">
            <img width="40" src="assets/images/floating/home.png" alt="" class="img-fluid for-light gf-mh-40" />
            <p class="gf-mb-0">Dashboard</p>
          </div>
        </div>
      </a>
    </div>
    <div class="float-hover col" *ngIf="isLoggedIn">
      <a [routerLink]="'/profile'">
        <div class="widget-content profile-tour">
          <div class="text-center flow">
            <img width="30" src="assets/images/floating/profile.png" alt="" class="img-fluid for-light gf-mh-40" />
            <p class="gf-mb-0">Profil</p>
          </div>
        </div>
      </a>
    </div>
    <div class="float-hover col" *ngIf="isLoggedIn">
      <a (click)="myUrlListModal.openModal()">
        <div class="widget-content url-tour">
          <div class="text-center flow">
            <img width="30" src="assets/images/floating/link.png" alt="" class="img-fluid for-light gf-mh-40" />
            <p class="gf-mb-0">List URL</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
<div *ngIf="isDashboard" class="guide-image" (click)="showTourGuide()">
  <img src="assets/images/guide.png" alt="Guide" />
</div>
<app-my-url-list #myUrlListModal></app-my-url-list>