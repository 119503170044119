import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../../pages/auth/auth.service";
import { StorageService } from "@services/storage.service";
import { UserService } from "@services/user.service";
import { Subscription, interval } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { SwalService } from "@services/swal.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit, OnDestroy {
  protected userData: any = {
    name: null,
    role_name: null
  };
  protected profileImg: "assets/images/dashboard/profile.jpg";
  protected remainingTime: number;
  private intervalSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private stgService: StorageService,
    private usrService: UserService,
    protected router: Router,
    private sW: SwalService,
  ) { }

  ngOnInit() {
    this.loadUserInfo();
    const sessionExpiration = this.stgService.getExpirationTimestamp();
    if (sessionExpiration) {
      this.startCountdown(sessionExpiration);
    }

    if (!this.stgService.isValidSSO()) {
      this.logout();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeInterval();
  }

  private loadUserInfo(): void {
    this.usrService.getUserInfo().subscribe({
      next: (res) => {
        if (res && res.data) {
          this.userData = {
            name: res.data.name,
            role_name: res.data.role_name,
          };
        }
      },
      error: (err) => { console.log(err); }
    });
  }

  private startCountdown(sessionExpiration: number): void {
    const currentTime = Date.now();
    const remainingMilliseconds = Math.max(sessionExpiration - currentTime, 0);
    this.remainingTime = remainingMilliseconds;

    const countdown$ = interval(1000).pipe(takeWhile(() => this.remainingTime > 0));

    this.intervalSubscription = countdown$.subscribe(() => {
      this.remainingTime -= 1000;
      if (this.remainingTime <= 0) {
        window.location.reload();
      } else if (this.remainingTime === 10 * 10 * 1000) {
        this.showReminderPopup();
      }
    });
  }

  private showPasswordChangePrompt(): void {
    Swal.fire({
      title: 'Password Reminder',
      text: 'Anda belum mengganti password selama 3 bulan, harap masukkan password baru:',
      input: 'password',
      inputPlaceholder: 'Masukkan password baru',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Password tidak boleh kosong!';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {

      }
    });
  }


  private showReminderPopup(): void {
    this.sW.confirmSwal(
      "Sesi login anda akan segera berakhir dalam 10 menit, klik Refresh untuk menambah 1 jam sesi, atau Logout untuk mengakhiri sesi",
      "Refresh",
      "Logout",
      () => this.refresh(),
      () => this.logout(),
    );
  }

  protected refresh(): void {
    this.authService.refresh().subscribe({
      next: (res) => {
        this.handleRefreshSuccess(res);
      },
      error: (err) => { console.log(err.error.message.error); },
    });
  }

  private handleRefreshSuccess(res: any): void {
    this.stgService.saveUser(res);
    const sessionExpiration = this.stgService.getExpirationTimestamp();
    if (sessionExpiration) {
      const currentTime = Date.now();
      const remainingMilliseconds = Math.max(sessionExpiration - currentTime, 0);
      this.remainingTime = remainingMilliseconds;
    }
    this.showSuccessMessage("Refresh session berhasil");
  }

  protected logout(): void {
    this.authService.logout().subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.authService.handleLogoutSuccess();
        }
      },
      error: (err) => { console.log(err.error.message.error); },
    });
  }

  private showSuccessMessage(message: string): void {
    this.sW.sSwal(message);
  }

  private unsubscribeInterval(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
