import { Injectable } from "@angular/core";
import { ShepherdService } from "angular-shepherd";
import { steps, stepsAuth } from "./tour-steps";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class MyTourService {

  constructor(
    private shepherdService: ShepherdService,
    private userService: UserService,
  ) { }

  initializeTour() {
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.userService.isLoggedIn().subscribe((res) => {
      res ?
        this.shepherdService.addSteps(stepsAuth) :
        this.shepherdService.addSteps(steps);
      this.shepherdService.start();
    });
  }
}
