import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CrudService } from "@services/crud.service";
import { Subscription } from "rxjs";
import { MainService } from "../../main/main.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-asset-publication-cu',
  templateUrl: './asset-publication-cu.component.html',
  styleUrls: ['./asset-publication-cu.component.scss']
})
export class AssetPublicationCuComponent implements OnInit, OnDestroy {
  private readonly FIELD = "asset-publication";
  private id: string;
  private subs: Subscription;
  protected assetID: string;
  protected assetName: string;
  protected formData: FormGroup;
  protected asset: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private crud: CrudService,
    private mService: MainService,
  ) {
    this.formData = this.formBuilder.group({
      id: [null],
      asset_id: [null, [Validators.required]],
      status: [null, [Validators.required]],
      blocking: [30, [Validators.required]],
    });

    this.subs = this.crud.getID(1).subscribe((id) => {
      this.id = id; if (this.id) { this.loadFormData(); }
    });
  }

  ngOnInit(): void {
    this.loadAsset();
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private loadAsset(): void {
    this.mService.getAssetList().subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.asset = res.data;
        } else {
          console.log(res.message);
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private loadFormData(): void {
    this.crud.getDataByIDRS(this.FIELD, this.id).subscribe((res) => {
      this.assetName = res.data.asset_name;
      console.log(res);
      this.formData.patchValue({
        id: this.id,
        asset_id: res.data.asset_id,
        status: res.data.status,
        blocking: res.data.blocking,
      });
    });
  }

  protected doSave(): void {
    Swal.fire({
      text: "Simpan data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const form: any = {
          id: this.id,
          asset_id: this.formData.get("asset_id")?.value,
          status: parseInt(this.formData.get("status")?.value),
          blocking: parseInt(this.formData.get("blocking")?.value),
        };

        if (this.id) {
          this.crud.updateDataRS(this.FIELD, form).subscribe({
            next: (res) => {
              if (res.status == 200) {
                this.crud.toggleVisible(1);
                this.crud.triggerLoadData(1);
              } else {
                Swal.fire({
                  text: res.message,
                  icon: "warning",
                });
              }
            },
            error: (err) => {
              Swal.fire({
                text: err.error?.error || "An unexpected error occurred",
                icon: "warning",
              });
            },
          });
        } else {
          this.crud.createDataRS(this.FIELD, form).subscribe({
            next: (res) => {
              if (res.status == 200) {
                this.crud.toggleVisible(1);
                this.crud.triggerLoadData(1);
              } else {
                Swal.fire({
                  text: res.error,
                  icon: "warning",
                });
              }
            },
            error: (err) => {
              Swal.fire({
                text: err.error.error,
                icon: "warning",
              });
            },
          });
        }
      }
    });
  }

  protected doCancel(): void {
    this.crud.toggleVisible(1);
  }
}
