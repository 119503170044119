<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body custom-datatable noscroll">
          <div class="custom-datatable">
            <h5 class="ribbon ribbon-bookmark ribbon-success fw-bold" (click)="goBack()">kembali</h5>
            <h5 class="ribbon ribbon-bookmark ribbon-right ribbon-primary fw-bold"><img class="mb-1" width="20" src="assets/images/logo/logo.png"> {{ module_title }}</h5>
            <br />
            <hr />

            <div class="d-flex justify-content-between mb-3 row">
              <div class="col-lg-7">
                <div class="input-group">
                  <span class="input-group-text"><i class="fa fa-search"></i></span>
                  <input class="form-control bg-light-primary" id="search" type="search" [(ngModel)]="filter.search"
                    (input)="onSearchInput($event)" />
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead class="bg-primary">
                  <tr>
                    <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(0, 'id')">
                      No <i [innerHTML]="sortE" [class.text-warning]="isSorted(0)"></i>
                    </th>
                    <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(1, 'name')">
                      Name <i [innerHTML]="sortE" [class.text-warning]="isSorted(1)"></i>
                    </th>
                    <th [ngClass]="['gf-wrap-text']" scope="col" (click)="sort(2, 'reserve_date')">
                      Tanggal Pelaksanaan <i [innerHTML]="sortE" [class.text-warning]="isSorted(2)"></i>
                    </th>
                    <th [ngClass]="['gf-wrap-text']" scope="col">
                      Jam Mulai
                    </th>
                    <th [ngClass]="['gf-wrap-text']" scope="col">
                      Jam Selesai
                    </th>
                    <th [ngClass]="['gf-wrap-text']" scope="col">
                      Status
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data; let i = index">
                    <td>{{ (currentPage - 1) * pageSize + (i + 1) }}</td>
                    <td>
                      {{ item.asset_name }}<br />
                      Domain: {{ item.host.domain }}<br />
                      Email: {{ item.host.email }}<br />
                      Phone: {{ item.host.phone }}
                    </td>
                    <td>{{ item.reserve_date | date: 'dd MMMM yyyy' }}</td>
                    <td>{{ item.start_time }}</td>
                    <td>{{ item.end_time }}</td>
                    <td>
                      <ng-container *ngIf="item.status === 1">
                        <i class="badge badge-sm badge-warning pointer">Menunggu Konfirmasi</i>
                      </ng-container>
                      <ng-container *ngIf="item.status === 2">
                        <i class="badge badge-sm badge-primary pointer">Berlangsung</i>
                        <hr/>
                        Kehadiran: {{ item.attendanceTotal }} / {{ item.capacity }} <br/>
                        <i class="badge badge-sm badge-info pointer" (click)="attendance(item.id, item.signature)">Link Absensi</i>
                        Ttd? <input type="checkbox" name="signature"
                        formControlName="signature" (click)="doSignature(item.id, $event)" [checked]="item.signature == '1'"/>
                      </ng-container>
                      <ng-container *ngIf="item.status === 3">
                        <i class="badge badge-sm badge-success pointer">Selesai</i><br/>
                        <small>Dihadiri: {{ item.attendanceTotal }} / {{ item.capacity }} Orang</small>
                      </ng-container>
                      <ng-container *ngIf="item.status === 4">
                        <i class="badge badge-sm badge-danger pointer">Tolak</i><br/>
                        <small><i class="text-danger">*</i> {{ item.reason }}</small>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="item.status === 2">
                        <i class="badge badge-sm badge-primary pointer" (click)="doDone(item.id)">Klik Selesai</i>
                      </ng-container>
                      <ng-container *ngIf="item.status === 2 || item.status === 3">
                        <i class="badge badge-sm badge-success pointer" (click)="report(item.id, item.signature)">Laporan Kehadiran</i>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between mt-3 mb-3">
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="selectedPageSize"
                (change)="changePageSize()">
                <option value="10">10 data</option>
                <option value="20">20 data</option>
              </select>
              <nav *ngIf="totalPages > 1" aria-label="..."> <!-- Hide pagination if only one page exists -->
                <ul class="pagination pagination-primary">
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(1)">First</a>
                  </li>
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage - 1)">Prev</a>
                  </li>

                  <li class="page-item" *ngFor="let page of getPagesArray()" [class.active]="currentPage === page">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(page)">{{ page }}</a>
                  </li>

                  <li class="page-item" [class.disabled]="currentPage === totalPages">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(currentPage + 1)">Next</a>
                  </li>
                  <li class="page-item" [class.disabled]="currentPage === totalPages">
                    <a class="page-link" href="javascript:void(0)" (click)="onPageChange(totalPages)">Last</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>