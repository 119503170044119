<div class="modal-header">
  <h4 class="modal-title">{{ data?.asset_name }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-0">
  <div class="container-fluid">
    <div class="row full-width-modal">
      <!-- Left side: Calendar -->
      <div class="col-lg-7 col-md-12 left-column p-3">
        <!-- Room Image -->
        <div class="col-12 d-flex align-items-center mb-3" *ngIf="data?.image">
          <a [routerLink]="['/fullscreen-image', data?.image]" target="_blank">
            <img class="img-fluid shadow" [src]="assetURL + data?.image" alt="Room Image">
          </a>
        </div>
        <div class="calendar-container shadow p-3">
          <app-calendar [pubID]="pubID"></app-calendar>
        </div>
      </div>

      <!-- Right side: Details -->
      <div class="col-lg-5 col-md-12 right-column p-3">
        <div class="row">

          <!-- Room Details Table -->
          <div class="col-12 mb-3">
            <table class="table table-bordered table-striped shadow">
              <thead>
                <tr>
                  <th>Nama Fasilitas</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data?.details">
                  <td>{{ item.name }}</td>
                  <td>{{ item.qty }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Reservation Form -->
          <div class="col-12 mb-3 shadow p-3">
            <h5>Form Reservasi</h5>
            <form (ngSubmit)="reserveRoom()" [formGroup]="formData">
              <div class="row">
                <div class="form-group col-lg-12 mb-1">
                  <label for="reserveTitle">Nama Kegiatan <small class="font-warning"><i>*</i></small></label>
                  <input type="text" class="form-control bg-light-info" id="reserveTitle" formControlName="reserveTitle" required>
                </div>
                <div class="form-group col-lg-4">
                  <label for="reserveDate">Tanggal <small class="font-warning"><i>*</i></small></label>
                  <input type="date" class="form-control bg-light-info" id="reserveDate" formControlName="reserveDate" required>
                </div>
                <div class="form-group col-lg-4">
                  <label for="startTime">Jam Mulai <small class="font-warning"><i>*</i></small></label>
                  <input type="time" class="form-control bg-light-info" id="startTime" formControlName="startTime" required>
                </div>
                <div class="form-group col-lg-4">
                  <label for="endTime">Jam Selesai <small class="font-warning"><i>*</i></small></label>
                  <input type="time" class="form-control bg-light-info" id="endTime" formControlName="endTime" required>
                </div>
              </div>
              <i class="text-primary">* Sebelum melakukan reservasi, pastikan telah melihat kalender ketersediaan tanggal dan jam di sisi kiri, terimakasih</i>
              <div class="form-group col-lg-12 mt-4">
                <button type="submit" class="btn btn-primary col-lg-12 fw-bold">Reservasi Sekarang <i class="fa fa-edit"></i></button>
              </div>
            </form>
          </div>

          <!-- Contact Info -->
          <div class="col-12 shadow p-3">
            <h5>Kontak</h5>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>Email:<br/> {{ data?.validator_email }}</label>
              </div>
              <div class="form-group col-lg-6">
                <label>Telp/WA:<br/> {{ data?.validator_phone }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
