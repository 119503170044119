import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CrudService } from "@services/crud.service";

interface AssetDetail {
  description: string;
  kode_unker: string;
  name: string;
  qty: number;
}

interface Asset {
  description: string;
  name: string;
  unker: string;
}

interface ReportData {
  asset: Asset;
  asset_detail: AssetDetail[];
}

@Component({
  selector: "app-asset-report",
  templateUrl: "./asset-report.component.html",
  styleUrls: ["./asset-report.component.scss"]
})
export class AssetReportComponent {
  private readonly FIELD = "asset-list";
  protected reportID: string;
  protected data: ReportData | null = null;

  constructor(
    private route: ActivatedRoute,
    private service: CrudService,
    private router: Router,
  ) {
    this.reportID = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.service.getReportRS(this.FIELD, this.reportID).subscribe({
      next: (res) => {
        this.data = res.data;
      },
      error: (err) => { console.log(err); },
    });
  }

  printReport() {
    window.print();
  }
}
