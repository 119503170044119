import { Component, Output, EventEmitter } from "@angular/core";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from "@services/crud.service";
import { PaginationService } from 'ngx-pagination';
import { MainService } from "../main.service";
import { DetailRoomModalComponent } from './detail-room-modal/detail-room-modal.component'; // Import the modal component
import { env } from "@env/env";

@Component({
  selector: "app-meeting-room",
  templateUrl: "./meeting-room.component.html",
  styleUrls: ["./meeting-room.component.scss"],
  providers: [PaginationService],
})
export class MeetingRoomComponent {
  rooms: any[] = [];
  unker: any[] = [];
  location: any[] = [];
  statusFilter: string = '';
  kodeUnkerFilter: string = '';
  subdistrictFilter: string = '';
  locationFilter: string = '';
  provinsiData: any[];
  kabupatenData: any[];
  kecamatanData: any[];
  protected page: number = 1;
  protected assetURL: string = env.asset_API;

  @Output() provinsiSelected = new EventEmitter<string>();
  @Output() kabupatenSelected = new EventEmitter<string>();
  @Output() kecamatanSelected = new EventEmitter<string>();

  constructor(
    private modalService: NgbModal,
    private crud: CrudService,
    private mService: MainService
  ) {
    this.mService.getList('1', null).subscribe((data: any) => {
      this.provinsiData = data.data || [];
    });
  }

  ngOnInit(): void {
    this.loadUnker();
    this.loadLocation();
    this.loadAssetPublications();
  }

  private loadAssetPublications(): void {
    const params = {
      status: this.statusFilter,
      kode_unker: this.kodeUnkerFilter,
      subdistrict: this.subdistrictFilter,
      location: this.locationFilter,
    };

    this.crud.getFilteredAssetPublications(params).subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.rooms = res.data;
        } else {
          console.log(res.message);
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private loadUnker(): void {
    this.crud.getUnker().subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.unker = res.data;
        } else {
          console.log(res.message);
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private loadLocation(): void {
    this.crud.getLocation().subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.location = res.data;
        } else {
          console.log(res.message);
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onStatusChange(status: string): void {
    this.statusFilter = status;
    this.loadAssetPublications();
  }

  onUnkerChange(kodeUnker: any): void {
    // let kode: any = null;
    // if (kodeUnker != null && kodeUnker !== undefined) {
    //   kode = kodeUnker.kode_unker;
    // }
    this.kodeUnkerFilter = kodeUnker; // Set the selected kode_unker or null
    this.loadAssetPublications(); // Reload the asset publications with the new filter
  }

  onLocationChange(location: any): void {
    this.locationFilter = location; // Set the selected kode_unker or null
    this.loadAssetPublications(); // Reload the asset publications with the new filter
  }

  // Event handler for province, district, and subdistrict selection
  onRegionSelected(el: string, level: string, selectedCode: string): void {
    this.subdistrictFilter = selectedCode; // Set the selected kode_unker
    this.loadAssetPublications();
    // Similar to your existing region selection logic
    this.mService.getList(level, selectedCode).subscribe((data: any) => {
      if (level === '2') {
        this.kabupatenData = data.data || [];
        this.kecamatanData = [];
      } else if (level === '3') {
        this.kecamatanData = data.data || [];
      }
    });
  }

  changePage(newPage: number): void {
    this.page = newPage;
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  openRoomModal(room: any): void {
    const modalRef: NgbModalRef = this.modalService.open(DetailRoomModalComponent, {
      size: 'xl',
      centered: true
    });
    modalRef.componentInstance.roomName = room.name;
    modalRef.componentInstance.roomID = room.asset_id;
    modalRef.componentInstance.pubID = room.id;
    modalRef.componentInstance.modal = modalRef;
  }
}
