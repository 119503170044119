export const env = {
  v1_API: "https://ais.pertanian.go.id/be/v1/",
  rs_API: "https://ais.pertanian.go.id/rs/v1/",
  asset_API: "https://ais.pertanian.go.id/be/",
  scraperAPI: "https://ais.pertanian.go.id/py/",
  // v1_API: "http://localhost:8181/be/v1/",
  // rs_API: "http://0.0.0.0:8080/rs/v1/",
  // asset_API: "http://localhost:8181/be/",
  TBtwnT_kX: "6Lffx0AnAAAAADLLQ5mUSvefcuOcmRnTBtwnT_kX",
  qu2h67l8: "283775411557-q5ivulv3ikgdiqu2h67l8emluud2e4q7.apps.googleusercontent.com",
  sort: "<i class='fa fa-sort'></i>",
  assetManagementKey: "gpFL_Yag0qTa7k1JxJ_0bc6wH4t8xOIvBBj7IoRkWkw",
};
