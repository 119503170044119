<div class="col-lg-12 mb-3" style="padding: 10px; background-color: rgb(236, 244, 244)">
  <div class="ribbon-wrapper-right card">
    <div class="card-body">
      <div class="ribbon ribbon-bookmark ribbon-success fw-bold">Create/Update</div>
      <button class="ribbon ribbon-clip-right ribbon-right ribbon-primary rounded" (click)="doSave()">Simpan</button>
      <button class="ribbon ribbon-clip-bottom-right ribbon-danger rounded" (click)="doCancel()">Batal</button>
      <form style="margin-bottom: 10px" [formGroup]="formData">
        <div class="row">
          <div class="mb-3 m-form__group col-lg-6">
            <label class="form-label">Pilih Asset</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <select class="form-control gf_border" formControlName="asset_id">
                <option
                  *ngFor="let item of asset"
                  [value]="item.id"
                  [selected]="item.name === assetName">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-4 d-none">
            <label class="form-label">Blocking <small><i>* (jeda waktu reservasi usai rapat)</i></small></label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <input class="form-control gf_border" type="number" formControlName="blocking" />
            </div>
          </div>
          <div class="mb-3 m-form__group col-lg-6">
            <label class="form-label">Status</label>
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-file-text-o"></i></span>
              <select class="form-control gf_border" formControlName="status">
                <option value="1"> Aktif </option>
                <option value="0"> Nonaktif </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>