import { Component } from "@angular/core";

@Component({
  selector: "app-swiper",
  templateUrl: "./swiper.component.html",
  styleUrls: ["./swiper.component.scss"],
})
export class SwiperComponent {
  protected thisYear: number = new Date().getFullYear();
}
