import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { env } from "../../../_env/env";
import { StorageService } from "../../../_services/storage.service";

@Injectable({
  providedIn: "root",
})
export class PolygonService {
  private loadDataSubject = new Subject<void>();
  public visibleChanged = new EventEmitter<boolean>();
  public loadData$ = this.loadDataSubject.asObservable();

  constructor(
    private http: HttpClient,
    private stgService: StorageService,
  ) { }

  getTable(filter: any): Observable<any> {
    const user = this.stgService.getUser(true);
    let params = new HttpParams();

    params = params.set("search", filter.search);
    params = params.set("page", filter.page);
    params = params.set("cat_id", filter.cat_id);
    params = params.set("page_size", filter.pageSize);

    return this.http.get(env.v1_API + "polygon-table", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      }),
      params: params,
    });
  }

  getCategory(): Observable<any> {
    const user = this.stgService.getUser(true);
    return this.http.get(env.v1_API + "polygon-category-list", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      }),
    });
  }

  createData(form: any): Observable<any> {
    const user = this.stgService.getUser(true);
    return this.http.post(env.v1_API + "polygon-create", form, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      }),
    });
  }

  getCategoryById(id: string) {
    let params = new HttpParams();
    params = params.set("id", id);

    return this.http.get<any>(env.v1_API + "polygon-category-by-id", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: params
    });
  }

  updateData(form: any): Observable<any> {
    const user = this.stgService.getUser(true);
    return this.http.post(env.v1_API + "polygon-update", form, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      }),
    });
  }

  loadData(id: string): Observable<any> {
    const user = this.stgService.getUser(true);
    let params = new HttpParams();

    params = params.set("id", id);
    return this.http.get(env.v1_API + "polygon-by-id", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      }),
      params: params
    });
  }

  deleteData(id: string): Observable<any> {
    const user = this.stgService.getUser(true);
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.data.token}`,
      }),
      body: { id: id },
    };

    return this.http.delete(env.v1_API + "polygon-delete", options);
  }
}
