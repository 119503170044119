import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { env } from "@env/env";
import { StorageService } from "@services/storage.service";
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(
    private http: HttpClient,
    private stgService: StorageService,
  ) {}

  private setAuthorizationHeaders(): HttpHeaders {
    const user = this.stgService.getUser(true);
    return user ? new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.data.token}`,
    }) : new HttpHeaders({ "Content-Type": "application/json" });
  }

  private makeAuthenticatedPost(url: string): Observable<any> {
    const headers = this.setAuthorizationHeaders();
    return this.http.post(env.v1_API + url, {}, { headers });
  }

  validateRole(): Observable<any> {
    const user = this.stgService.getUser(true);
    if (user) {
      const params = new HttpParams().set("email", user.data.email);
      return this.http.get(env.v1_API + "role-validation", { params, headers: this.setAuthorizationHeaders() })
        .pipe(
          catchError(() => of(null))
        );
    }
    return of(null);
  }

  login(username: string, password: string, is_google: boolean): Observable<any> {
    return this.http.post(env.v1_API + "user/login", { username, password, is_google }, this.httpOptions);
  }

  logout(): Observable<any> {
    return this.makeAuthenticatedPost("user/logout");
  }

  handleLogoutSuccess(): void {
    window.sessionStorage.clear();
    window.sessionStorage.removeItem("auth-user");
    window.location.href = "/dashboard";
  }

  refresh(): Observable<any> {
    return this.makeAuthenticatedPost("user/refresh");
  }

  register(name: string, username: string, email: string, password: string, is_google: boolean, nip: string, unker: string): Observable<any> {
    return this.http.post(env.v1_API + "user/register", { name, username, email, password, is_google, nip, unker }, this.httpOptions);
  }

  verifyEmail(verificationCode: string): Observable<any> {
    return this.http.post(env.v1_API + "user/verify-email", { verification_code: verificationCode }, this.httpOptions);
  }

  IsRegistered(email: string): Observable<any> {
    return this.http.post(env.v1_API + "user/is-registered", { email }, this.httpOptions);
  }
}
