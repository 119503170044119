<div id="report-content" class="report-container">
  <h1 class="report-title">Laporan Kehadiran</h1>
  <button class="no-print fr btn text-black shadow" (click)="printReport()"><i class="fa fa-print"></i> Print</button>
  <table class="asset-detail-table">
    <thead>
      <tr>
        <th>Kegiatan Rapat</th>
        <th>{{ data.asset?.title }}</th>
      </tr>
      <tr>
        <th>Tanggal & Jam Pelaksanaan</th>
        <th>{{ formatDateTime(data.asset?.date, data.asset?.start_time, data.asset?.end_time) }}</th>
      </tr>
      <tr>
        <th>Pelaksana / Penyelenggara</th>
        <th>{{ data.asset?.domain }} - {{ data.asset?.email }}</th>
      </tr>
      <tr>
        <th>Nama Ruang Rapat</th>
        <th>{{ data.asset?.name }}</th>
      </tr>
      <tr>
        <th>Fasilitas</th>
        <th>Jumlah</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let facility of data.asset_facility">
        <td>{{ facility.name }}</td>
        <td>{{ facility.qty }}</td>
      </tr>
    </tbody>
  </table>

  <h4 class="report-title mt-2" style="float:left;">Absensi</h4>
  <table class="asset-detail-table">
    <thead>
      <tr>
        <th>No</th>
        <th>Nama</th>
        <th>Deskripsi</th>
        <th>Email & Telp/WA</th>
        <th *ngIf="issig == 1">Ttd</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detail of data.asset_detail; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ detail.name }}</td>
        <td>{{ detail.description }}</td>
        <td>{{ detail.email }}<br/>{{ detail.phone }}</td>
        <td *ngIf="issig == 1">
          <!-- [ngClass]="{'float-left': i % 2 === 0, 'float-right': i % 2 !== 0}" -->
          <div class="float-left">
            {{ i + 1 }}.
            <img
              width="70"
              [src]="detail.signature"
              *ngIf="detail.signature">
        </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>