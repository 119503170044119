<div class="header-panel">
  <div class="row">
    <div class="col-lg-12 header-content">
      <a routerLink="/" class="logo">
        <img width="50" src="assets/images/logo/logo.png" alt="Logo" />
      </a>
      <div class="separator"></div>
      <div class="header-text">
        <h5>{{ title }}</h5>
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</div>
<div class="org-background">
  <div class="org-background-panel">
    <div leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)" style="height: 500px;"></div>
  </div>
</div>