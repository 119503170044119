import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from "@services/crud.service";
import { Observable } from 'rxjs';
import { env } from "@env/env";
import { ToastrService } from "ngx-toastr";
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { I18nPluralPipe } from "@angular/common";
import { CalendarEventTitleFormatter } from "angular-calendar";
import Swal from "sweetalert2";

@Component({
  selector: 'app-detail-room-modal',
  templateUrl: './detail-room-modal.component.html',
  styleUrls: ['./detail-room-modal.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useFactory: adapterFactory
    },
    CalendarEventTitleFormatter,
    I18nPluralPipe
  ]
})
export class DetailRoomModalComponent implements OnInit {
  private readonly FIELD: string = "asset-reservation";

  @Input() roomName: string;
  @Input() roomID: string;
  @Input() pubID: string;
  @Input() modal: NgbModalRef;
  @Output() reserved = new EventEmitter<void>();
  data: any = {};
  protected assetURL: string = env.asset_API;
  formData: FormGroup;

  constructor(
    private crud: CrudService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
  ) {
    this.formData = this.formBuilder.group({
      reserveTitle: [null, [Validators.required]],
      reserveDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.loadDetail();
  }

  reserveRoom(): void {
    if (this.formData.invalid) {
      this.toast.warning("Perhatikan form yang wajib diisi *", "Warning", { timeOut: 2000 });
      return;
    }

    const reserveTitle = this.formData.get('reserveTitle')?.value;
    const reserveDate = this.formData.get('reserveDate')?.value;
    const startTime = this.formData.get('startTime')?.value;
    const endTime = this.formData.get('endTime')?.value;

    if (!reserveTitle) {
      Swal.fire({
        text: "Judul harus diisi.",
        icon: "warning",
      });
      return;
    }

    if (!reserveDate || !startTime || !endTime) {
      Swal.fire({
        text: "Tanggal dan waktu harus diisi.",
        icon: "warning",
      });
      return;
    }

    const reservationStart = new Date(`${reserveDate}T${startTime}`);
    const reservationEnd = new Date(`${reserveDate}T${endTime}`);

    if (isNaN(reservationStart.getTime()) || isNaN(reservationEnd.getTime())) {
      Swal.fire({
        text: "Tanggal atau waktu tidak valid.",
        icon: "warning",
      });
      return;
    }

    this.checkAvailability(reservationStart).subscribe(isAvailable => {
      if (isAvailable) {
        Swal.fire({
          text: "Reservasi sekarang ?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            const form: any = {
              asset_publication_id: this.pubID,
              title: this.formData.get("reserveTitle")?.value,
              reserve_date: this.formData.get("reserveDate")?.value,
              start_time: this.formData.get("startTime")?.value,
              end_time: this.formData.get("endTime")?.value,
            };
            this.crud.validateTimeRS(form).subscribe({
              next: (res) => {
                console.log(res);
                if (res.data === false) {
                  this.createReservation(form);
                } else {
                  Swal.fire({text: "Jam pada tanggal tersebut sudah direservasi, silahkan pilih tanggal/jam lainnya", icon: "warning", });
                }
              },
              error: (err) => {
                Swal.fire({
                  text: err.error.error,
                  icon: "warning",
                });
              },
            });
          }
        });
      } else {
        Swal.fire({
          text: "Jam sudah di reservasi, reservasi tidak dapat dilanjutkan",
          icon: "warning",
        });
      }
    });
  }

  private createReservation(form: any) {
    this.crud.createDataRS(this.FIELD, form).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.modal.dismiss();
          this.reserved.emit();
          Swal.fire({ text: "Reservasi berhasil, menunggu konfirmasi PIC", icon: "success" });
        } else {
          Swal.fire({
            text: res.error,
            icon: "warning",
          });
        }
      },
      error: (err) => {
        Swal.fire({
          text: err.error.error,
          icon: "warning",
        });
      },
    });
  }

  private checkAvailability(startTime: Date) {
    const reservedTime = new Date('2024-09-19T08:00:00'); // Example reserved time
    return new Observable<boolean>(observer => {
      if (startTime.getTime() === reservedTime.getTime()) {
        observer.next(false);
      } else {
        observer.next(true);
      }
      observer.complete();
    });
  }

  private loadDetail(): void {
    this.crud.getDetail(parseInt(this.roomID)).subscribe({
      next: (res) => {
        console.log(res);
        if (res.status === 200) {
          this.data = res.data;
        } else {
          console.log(res.message);
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  close(): void {
    this.modal.dismiss();
  }
}
