<div class="form-container">
  <form [formGroup]="attendanceForm" (ngSubmit)="onSubmit()">
    <div class="form-card">
      <h4>Input Data Kehadiran</h4>
      <hr/>
      <div class="form-group">
        <!-- <label for="name">Nama <small class="font-warning"><i>*</i></small></label> -->
        <input type="text" id="name" formControlName="name" placeholder="Nama Lengkap" />
      </div>
      <div class="form-group">
        <!-- <label for="description">Deskripsi <small class="font-warning"><i>*</i></small></label> -->
        <input type="text" id="description" formControlName="description" placeholder="Unit Kerja/Divisi/lainnya" />
      </div>
      <div class="form-group">
        <!-- <label for="email">Email</label> -->
        <input type="email" id="email" formControlName="email" placeholder="email@gmail.com" />
      </div>
      <div class="form-group">
        <!-- <label for="phone">Telepon/WA</label> -->
        <input type="number" id="phone" formControlName="phone" placeholder="08113333xxx" />
      </div>
      <div class="form-group" *ngIf="issig == 1">
        <button style="padding: 0 10px 0 10px; color:#fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); float:right; background-color: rgb(99, 145, 130); margin-bottom: -20px;" class="btn" type="button">
        <i class="fa fa-pencil"></i> <small><i>Tanda Tangan</i></small></button>
        <canvas #canvas width="auto" height="150"></canvas>
        <button style="padding: 0 10px 0 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); float:left; background-color: aquamarine; margin-top: -20px; margin-bottom: 20px; color:#000;" class="btn" type="button" (click)="clear()">
        <i class="fa fa-refresh"></i> <small><i>Ulang Ttd</i></small></button>
      </div>
      <div class="form-group">
          <button class="btn btn-primary col-lg-12" type="submit" [disabled]="attendanceForm.invalid">Submit</button>
      </div>
    </div>
  </form>
</div>
