import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { UserService } from '../../../_services/user.service';
import { CrudService } from '../../../_services/crud.service';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2";
const msg = Swal.mixin({ timer: 3000, timerProgressBar: true });

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Syarat dan Ketentuan</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p>Saya telah menyetujui syarat dan ketentuan yang berlaku</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Lanjut</button>
    </div>
  `,
})
export class NgbdModalContent {
  @Input() name;
  constructor(protected activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  protected form: any = {
    name: null,
    username: null,
    email: null,
    password: null,
    confirm_password: null,
    nip: null,
    unker: null,
  };
  protected activeTab: string = 'umum';
  protected isEqual = true;
  protected isLoggedIn = false;
  protected isRegisterFailed = false;
  protected errorMessage = '';
  protected acceptedTerms = false;
  protected unker: any[] = [];
  protected searchTerm: string = '';
  protected filteredUnker = [];

  constructor(
    private authService: AuthService,
    private usrService: UserService,
    public router: Router,
    private modalService: NgbModal,
    private crud: CrudService
  ) { }

  ngOnInit(): void {
    this.loadUnker();
  }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  onUnkerChange(kodeUnker: any): void {
    this.form.unker = kodeUnker.kode_unker;
  }

  private loadUnker(): void {
    this.crud.getUnker().subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.unker = res.data;
        }
      }, error: (err) => {
        console.log(err);
      }
    });
  }

  validatePegawai(): boolean {
    if (this.activeTab === 'pegawai') {
      if (this.form.unker === null || this.form.nip === null) {
        return false;
      }
    }

    return true;
  }

  onSubmit(): void {
    const { name, username, email, password, confirm_password, nip, unker } = this.form;

    if (!this.passwordMatchValidator(password, confirm_password)) {
      return;
    }

    if (!this.validatePegawai()) {
      msg.fire('Unit Kerja / NIP', 'Input tidak boleh kosong', 'warning');
      return;
    }

    let nipVal: any = null;
    if (nip !== null) {
      nipVal = String(nip);
    }

    this.authService.register(name, username, email, password, false, nipVal, unker).subscribe({
      next: (res) => {
        msg.fire(
          'Berhasil',
          'Silahkan login kedalam akun anda menggunakan data tersebut',
          'success',
        );

        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 3000);
      },
      error: (err) => {
        this.isRegisterFailed = true;
        this.errorMessage = err.error.message.error;
      },
    });
  }

  openModal() {
    if (!this.acceptedTerms) {
      const modalRef = this.modalService.open(NgbdModalContent);
      modalRef.componentInstance.name = 'Syarat dan Ketentuan';
    }
  }

  formatUsername(event: any): void {
    const inputValue = event.target.value;
    let formattedValue = inputValue.trimStart();
    formattedValue = formattedValue.replace(/\s/g, '_');
    formattedValue = formattedValue.replace(/[^a-zA-Z0-9_]/g, '');
    event.target.value = formattedValue;
    this.form.username = formattedValue;
  }

  formatEmail(event: any): void {
    const inputValue = event.target.value;
    let formattedValue = inputValue.trimStart();
    formattedValue = formattedValue.replace(/[^a-zA-Z0-9@.]/g, '');
    const atSymbolCount = (formattedValue.match(/@/g) || []).length;
    if (atSymbolCount > 1) {
      formattedValue = formattedValue.replace(/@+$/, '');
    }
    event.target.value = formattedValue;
    this.form.email = formattedValue;
  }


  passwordMatchValidator(password: string, confirmPassword: string): boolean {
    this.isEqual = false;
    if (password === confirmPassword) {
      this.isEqual = true;
    }
    return this.isEqual;
  }

  passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const minLength = 8;

    if (!hasUpperCase || !hasLowerCase || !hasNumber || value.length < minLength) {
      return { passwordRequirements: true };
    }

    return null;
  }
}
