import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { env } from "@env/env";

@Injectable({
  providedIn: "root",
})
export class AssetManagementService {
  constructor(
    private http: HttpClient,
  ) { }

  private getHeaders(dataKey: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: `data-key ${dataKey}`,
    });
  }

  public getAllowedDomain(dataKey: string): Observable<any> {
    return this.http.get(env.rs_API + "allowed-domains", {
      headers: this.getHeaders(dataKey),
    });
  }

}
